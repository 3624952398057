// Import necessary modules
import { IconButton, useTheme,Box } from "@mui/material";
import { useState, useEffect } from "react";

// Update StandardButtonProps to include the sx property
type StandardButtonProps = {
  text: string; // i18n key
  action: (e: any) => void;
  disabled?: boolean;
  style?: {};
};

// StandardButton Component
export  function StandardButton({ text, action, disabled, style }: StandardButtonProps) {

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => {
        setIsClicked(false);
      }, 1); 

      return () => clearTimeout(timer);
    }
  }, [isClicked]);

  const handleClick = (e: any) => {
    if (!isClicked) {
      action(e);
      setIsClicked(true);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: '#172339',
            color: '#faf8f6',
          },
          backgroundColor: '#e84b4bee',
          color: '#172339',
          fontSize: 18,
          fontWeight: 700,
          border: '1px solid #172339',
          padding: 0,
          margin: 0,
          borderRadius: '36px',
          width: '200px',
          height: '40px',
          cursor: 'pointer',
          ...style
        }}
        disabled={disabled || isClicked}
        onClick={(e) => handleClick(e)}
      >
        {(text)} {/* Translated button text */}
      </IconButton>
    </Box>
  );
}

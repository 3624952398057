import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Link } from '@mui/material'; // Ensure Link is imported from MUI
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { StripeUtil } from 'common.f6st.com';
import { Customer, AppError, Log } from 'base.f6st.com';

type StripeOnboardingProps = {
  customer: Customer | undefined;
  clientSecret: string;
  onOnboardingExit: () => void;
};

export const StripeOnboarding: React.FC<StripeOnboardingProps> = ({ customer, clientSecret, onOnboardingExit }) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>(undefined);
  const [error, setError] = useState(false);

  // useRef to track if initialization has already occurred
  const hasInitialized = useRef(false);

  useEffect(() => {
    const initializeStripeConnect = async () => {
      // If already initialized, skip the initialization to prevent duplicate claims
      if (hasInitialized.current === true) {
        Log.debug("Stripe Connect instance has already been initialized. Skipping re-initialization.");
        return;
      }

      Log.debug("Initializing Stripe Connect instance.");
      hasInitialized.current = true;
      try {
        const instance = await StripeUtil.useStripeConnect(clientSecret);
        Log.debug("Stripe connect instance has been created", instance);
        setStripeConnectInstance(instance);
      } catch (err) {
        Log.error("Error initializing Stripe Connect:", err);
        setError(true);
      }
    };

    initializeStripeConnect();
  }, [clientSecret, customer]); // Added 'customer' to dependencies for completeness

  return (
    <Box>
      {stripeConnectInstance && (
        <>
          <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding onExit={onOnboardingExit} />
          </ConnectComponentsProvider>
        </>
      )}
      {error && <Typography color="error">Something went wrong!</Typography>}
    </Box>
  );
};

import React, { useState, ChangeEvent } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { GeoLocationUtil, MailClient, SecondaryButton, SecurityCodeClient, StandardButton } from 'common.f6st.com';
import { toast } from 'react-toastify';
import { CallerType } from 'base.f6st.com';

interface EmailChangeDialogProps {
  open: boolean;
  onClose: () => void;
  currentEmail: string;
  userId: string;
  onSave: (data: { email: string }) => void;
}

export const EmailChangeDialog: React.FC<EmailChangeDialogProps> = ({ open, onClose, currentEmail, userId, onSave }) => {
  const [email, setEmail] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [codeId, setCodeId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleVerificationCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  const sendVerificationCode = async () => {
    setError(null);
    if (email === currentEmail) {
      setError("The new email must be different from the current email.");
      return;
    }
    if (!email.includes('@')) {
      setError("Please enter a valid email address.");
      return;
    }

    try {

      const subject = "F6ST verification code";
      const body = "Your login verification code is: {code}";
      const codeId = await SecurityCodeClient.createSecurityCodeMail(CallerType.ADMIN, email.trim(), subject, body, userId);
   
      if (codeId) {
        setCodeId(codeId);
        toast.success("A verification code has been sent to your new email address.");
      } else {
        setError("Failed to send verification code. Please try again.");
      }
    } catch (err) {
      setError("Failed to send verification code. Please try again.");
    }
  };

  const handleSave = () => {
    if (!verificationCode) {
      setError("Please enter the verification code.");
      return;
    }
    onSave({ email });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Change Email
        <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!codeId ? (
          <TextField
            label="New Email"
            type="email"
            fullWidth
            sx={{ mt: 1 }}
            value={email}
            onChange={handleEmailChange}
            error={Boolean(error)}
            helperText={error}
          />
        ) : (
          <TextField
            label="Verification Code"
            type="text"
            fullWidth
            sx={{ mt: 1 }}
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            error={Boolean(error)}
            helperText={error}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
          <SecondaryButton text="Cancel" action={onClose} />
          {!codeId ? (
            <StandardButton text="Next" action={sendVerificationCode} />
          ) : (
            <StandardButton text="Save" action={handleSave} />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

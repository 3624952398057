import { useEffect, useState, useRef } from 'react';
import { Box, Container, Paper, Typography, Tooltip, Grid } from "@mui/material";
import { formatDistanceToNow } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/CheckCircle';
import NotPaidIcon from '@mui/icons-material/Error';
import HouseIcon from '@mui/icons-material/House';
import { useAppContext } from '../components/AppProvider';
import { OrderDate, OrderFilter } from '../components/order/OrderFilterDrawer';
import { CustomAppBar } from '../components/CustomAppBar';
import { Order, Customer, OrderStatus, Log, PaymentStatus, CallerType } from 'base.f6st.com';
import { OrderClient } from 'common.f6st.com';
import { OrderDetailDialog } from '../components/order/OrderDetailsDialog';

type OrdersPageProps = {};

export const OrdersPage = ({ }: OrdersPageProps) => {
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { orderFilter, orders, setOrders, loadCustomer } = useAppContext();
  const customer = useAppContext().getCustomer();
  const fetchInitiated = useRef(false);

  const fetchOrders = async (customer: Customer, orderFilter: OrderFilter) => {
    try {
      let fromDate = new Date();
      let toDate = undefined;

      switch (orderFilter.orderDate) {
        case OrderDate.LAST_1H:
          fromDate.setHours(fromDate.getHours() - 1);
          break;
        case OrderDate.LAST_4H:
          fromDate.setHours(fromDate.getHours() - 4);
          break;
        case OrderDate.LAST_24H:
          fromDate.setDate(fromDate.getDate() - 1);
          break;
        case OrderDate.DATE_RANGE:
          if (orderFilter.exactDateFrom) {
            fromDate = orderFilter.exactDateFrom;
            fromDate.setHours(0, 0, 0, 0);
            if (orderFilter.exactDateTo) {
              toDate = orderFilter.exactDateTo;
              toDate.setHours(23, 59, 59, 999);
            }
          } else {
            throw new Error('Exact dates must be provided for EXACT_DATES option');
          }
          break;
        default:
          break;
      }

      const orders: Order[] = await OrderClient.queryOrders(CallerType.ADMIN, customer.id, orderFilter.orderStatus as OrderStatus, fromDate, toDate);
      Log.debug('Orders fetched:', orders);
      setOrders(orders);
    } catch (error) {
      Log.error('Failed to fetch orders:', error);
    }
  };

  useEffect(() => {
    const fetchCustomerAndOrders = async () => {
      const customerNew = await loadCustomer(customer.id, customer.businessSettings.languageCodePrimary);
      await fetchOrders(customerNew, orderFilter);
    };

    if (!fetchInitiated.current) {
      fetchCustomerAndOrders();
      fetchInitiated.current = true;
    }
  }, [customer.id, customer.businessSettings.languageCodePrimary, loadCustomer, orderFilter]);

  const filteredOrders = filterOrders(customer, orders, orderFilter);

  const handleOrderClick = (order: Order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  const sortedOrders = filteredOrders.sort((a, b) => {
    if (a.priority === b.priority) {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    }
    return a.priority ? -1 : 1;
  });

  const getNoOrdersMessage = () => {
    let message = 'Currently no orders are available';
    switch (orderFilter.orderDate) {
      case OrderDate.LAST_1H:
        message += ' in the last 1 hour';
        break;
      case OrderDate.LAST_4H:
        message += ' in the last 4 hours';
        break;
      case OrderDate.LAST_24H:
        message += ' in the last 24 hours';
        break;
      case OrderDate.DATE_RANGE:
        if (orderFilter.exactDateFrom && orderFilter.exactDateTo) {
          message += ` from ${orderFilter.exactDateFrom.toLocaleDateString()} to ${orderFilter.exactDateTo.toLocaleDateString()}`;
        } else if (orderFilter.exactDateFrom) {
          message += ` from ${orderFilter.exactDateFrom.toLocaleDateString()}`;
        }
        break;
      default:
        break;
    }
    if (orderFilter.orderStatus) {
      message += ` with order status ${orderFilter.orderStatus}`;
    }
    return message;
  };

  return (
    <>
      <CustomAppBar showOrderFunctions backButton pageTitle={`Orders (${sortedOrders.length})`} refreshOrders={() => fetchOrders(customer, orderFilter)} />
      <Box sx={{ display: 'flex', mt: '67px', justifyContent: 'center', height: '100vh' }}>
        <Container maxWidth="lg" sx={{ marginBottom: '120px' }}>
          <Grid container spacing={3} sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            {sortedOrders.length > 0 ? (
              sortedOrders.map((order: Order) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={order.id}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: '12px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      backgroundColor: order.priority ? 'lightpink' : 'white',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleOrderClick(order)}
                  >

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {order.paymentStatus === PaymentStatus.PAID ? (
                          <Tooltip title="Payment Received">
                            <PaidIcon style={{ color: 'green' }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="No Payment">
                            <NotPaidIcon style={{ color: 'red' }} />
                          </Tooltip>
                        )}
                        <Typography variant="body2" component="div" sx={{ marginLeft: '8px' }}>
                          {`${order.totalAmount?.toFixed(2)}${order.currency}`}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTimeIcon />
                      <Typography variant="body2" component="div">
                        {`Ordered ${formatDistanceToNow(new Date(order.date), { addSuffix: true })}`}
                      </Typography>
                    </Box>
                    {order.qrCode.locationName && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <HouseIcon />
                        <Typography variant="body2" component="div">
                          {order.qrCode.locationName}
                        </Typography>
                      </Box>
                    )}
                    {order.items.map(item => {
                      const sizeNameId = item.product.sizes?.find(size => size.id === item.sizeId)?.id;
                      const sizeName = sizeNameId ? order.texts[sizeNameId] : '';

                      const extraNames = item.extraIds?.map(extraId => {
                        const extraNameId = item.product.extras?.find(extra => extra.id === extraId)?.id;
                        return extraNameId ? order.texts[extraNameId] : '';
                      }).filter(Boolean).join(', ');

                      return (
                        <Box key={item.id}>
                          <Typography variant="body2" sx={{ mb: -1 }}>
                            {`${item.quantity} x ${order.texts[item.product.id]} ${sizeName ? `(${sizeName})` : ''} ${extraNames ? `+ ${extraNames}` : ''}`}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item>
                <Typography variant="h6" component="div" sx={{ textAlign: 'center', mt: 4 }}>
                  {getNoOrdersMessage()}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
      {selectedOrder && (
        <OrderDetailDialog
          open={dialogOpen}
          order={selectedOrder}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
};

const filterOrders = (customer: Customer, orders: Order[], filter: OrderFilter): Order[] => {
  return orders.filter((order: Order) => {
    if (filter.orderId && filter.orderId.length > 0 && order.id !== filter.orderId) {
      return false;
    }
    if (filter.qrCodeId && filter.qrCodeId.length > 0 && order.qrCode.id !== filter.qrCodeId) {
      return false;
    }
    if (filter.qrCodeLocations && order.qrCode.locationName && filter.qrCodeLocations.length > 0 && !filter.qrCodeLocations.includes(order.qrCode.locationName)) {
      return false;
    }
    if (filter.categoryId && filter.categoryId.length > 0) {
      const categoryExists = order.items.some(item => item.categoryId === filter.categoryId);
      if (!categoryExists) {
        return false;
      }
    }
    if (filter.paymentStatus && filter.paymentStatus.length > 0 && order.paymentStatus !== filter.paymentStatus) {
      return false;
    }
    if (filter.productId && filter.productId.length > 0 && !order.items.some(item => item.product.id === filter.productId)) {
      return false;
    }
    if (filter.buyerId && filter.buyerId.length > 0 && order.buyerId !== filter.buyerId) {
      return false;
    }
    if (filter.teamId && filter.teamId.length > 0) {
      const team = customer.businessSettings.teams?.find(team => team.id === filter.teamId);
      if (team && team.categoryIds.length > 0) {
        const orderHasTeamCategory = order.items.some(item => team.categoryIds.includes(item.categoryId));
        if (!orderHasTeamCategory) {
          return false;
        }
      }
    }
    return true;
  });
};

import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box, Slider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cropper from 'react-easy-crop';
import pica from 'pica';
import { S3Client } from 'common.f6st.com';
import { StandardButton, SecondaryButton } from "common.f6st.com"; // Assuming these are available
import { CallerType } from 'base.f6st.com';

type ImageResizerDialogProps = {
    open: boolean;
    onClose: () => void;
    imageSrc: string | null;
    onSave: (fileId: string) => void;
    customerId: string;
    imgId: string;
};

const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

const getCroppedImg = async (imageSrc: string, crop: { x: number; y: number; width: number; height: number }) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('Failed to get canvas context');
    }

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
    );

    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 600;
    resizedCanvas.height = 600;
    await pica().resize(canvas, resizedCanvas);

    return new Promise<Blob>((resolve, reject) => {
        resizedCanvas.toBlob((file) => {
            if (file) {
                resolve(file);
            } else {
                reject(new Error('Canvas is empty'));
            }
        }, 'image/webp');
    });
};

export const ImageResizerDialog: React.FC<ImageResizerDialogProps> = ({ open, onClose, imageSrc, onSave, customerId, imgId }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<{ x: number; y: number; width: number; height: number } | null>(null);

    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: { x: number; y: number; width: number; height: number }) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleSave = async () => {
        if (imageSrc && croppedAreaPixels) {
            const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedFile = new File([croppedBlob], 'croppedImage.webp', { type: 'image/webp' });
            const fileId = await S3Client.uploadImage(CallerType.ADMIN, croppedFile, customerId);
            onSave(fileId);
            onClose();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Image Resizer
                <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box position="relative" width="100%" height={400} bgcolor="grey.200">
                    {imageSrc && (
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    )}
                </Box>
                <Box mt={2}>
                    <Typography variant="h6">Zoom</Typography>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e, newZoom) => setZoom(newZoom as number)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
                    <SecondaryButton text="Cancel" action={onClose} />
                    <StandardButton text="Confirm" action={handleSave} />
                </Box>
            </DialogActions>
        </Dialog>
    );
};

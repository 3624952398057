import { useState, useEffect } from 'react';
import { Container, FormControl, TextField, Paper, Stack, Alert, Box, IconButton, Link } from "@mui/material";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ContentCopy } from '@mui/icons-material';
import { StandardButton, LanguageSelector, SecondaryButton } from 'common.f6st.com';
import { toast } from 'react-toastify';
import { useAppContext } from '../components/AppProvider';
import { CustomAppBar } from '../components/CustomAppBar';
import { EmailChangeDialog } from '../components/dialogs/EmailChangeDialog';
import { ResetPasswordDialog } from '../components/dialogs/ResetPasswordDialog';
import { UserNameChangeDialog } from '../components/dialogs/UserNameChangeDialog';
import { useTranslation } from 'react-i18next';

type UserProfileInputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const ProfilePage = () => {
  const appContext = useAppContext();
  const { i18n } = useTranslation();

  if (!appContext) {
    throw new Error("AppContext must be used within an AppProvider");
  }

  const [showEmailChangeDialog, setShowEmailChangeDialog] = useState(false);
  const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(false);
  const [showUserNameChangeDialog, setShowUserNameChangeDialog] = useState(false);
  const customer = appContext.getCustomer();
  const loggedInUser = appContext.getLoggedInUser();
  const logout = appContext.logout;

  useEffect(() => {
    if (loggedInUser) {
      setInitialValues({
        name: loggedInUser.name,
        email: loggedInUser.email,
        password: '',
        confirmPassword: '',
      });
    }
  }, [loggedInUser]);

  if (!loggedInUser || !customer) return null;

  const handleLogout = () => {
    if (logout) logout();
  };

  const handleEmailChange = () => {
    setShowEmailChangeDialog(true);
  };

  const handlePasswordChange = () => {
    setShowPasswordChangeDialog(true);
  };

  const handleUserNameChange = () => {
    setShowUserNameChangeDialog(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(loggedInUser.id);
    toast.success("User Code copied to clipboard");
  };

  const handleEmailSave = (data: { email: string }) => {
    loggedInUser.email = data.email;
    setInitialValues({ name: loggedInUser.name, email: loggedInUser.email, password: '', confirmPassword: '' });
  };

  const handleUserNameSave = (data: { name: string }) => {
    loggedInUser.name = data.name;
    setInitialValues({ name: loggedInUser.name, email: loggedInUser.email, password: '', confirmPassword: '' });
  };

  const [initialValues, setInitialValues] = useState<UserProfileInputs>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  return (
    <>
      <CustomAppBar backButton pageTitle="Profile" />
      <Container>
        <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true} // This ensures that form values are updated when initialValues change
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // handle form submission
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <FormControl component="div" fullWidth sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Field
                      as={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      required
                      sx={{ flexGrow: 1 }}
                    />
                    <StandardButton text="Change" action={handleUserNameChange} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Field
                      as={TextField}
                      name="email"
                      label="Email"
                      type="email"
                      variant="outlined"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    <StandardButton text="Change" action={handleEmailChange} />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TextField
                      label="Password"
                      type="password"
                      variant="outlined"
                      value="******"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    <StandardButton text="Change" action={handlePasswordChange} />
                  </Box>

                  <LanguageSelector i18n={i18n} small={false} />

                  <Alert severity="success">
                    Your User Code for the login: <b>{loggedInUser.id}</b>
                    <IconButton onClick={copyToClipboard} size="small" sx={{ marginLeft: 1 }}>
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </Alert>
                  <Alert severity="info">
                  To delete your user account, contact the administrator who created it. To delete the entire F6ST account, email <Link href="mailto:info@f6st.com">info@f6st.com</Link>.
                  </Alert>

                  <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                    <SecondaryButton text="Logout" action={handleLogout} />
                  </Stack>

                </FormControl>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>

      <EmailChangeDialog
        open={showEmailChangeDialog}
        onClose={() => setShowEmailChangeDialog(false)}
        currentEmail={loggedInUser.email}
        userId={loggedInUser.id}
        onSave={handleEmailSave}
      />
      <ResetPasswordDialog
        open={showPasswordChangeDialog}
        email={loggedInUser.email}
        onClose={() => setShowPasswordChangeDialog(false)}
        userId={loggedInUser.id}
      />
      <UserNameChangeDialog
        open={showUserNameChangeDialog}
        onClose={() => setShowUserNameChangeDialog(false)}
        user={loggedInUser}
        onSave={handleUserNameSave}
      />
    </>
  );
};

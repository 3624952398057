import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, IconButton, Table, TableBody, TableCell, TableRow, TextField, Switch, Checkbox, FormControlLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { OrderClient, ReceiptLink, SecondaryButton, StandardButton } from 'common.f6st.com';
import CloseIcon from '@mui/icons-material/Close';
import WebIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppProvider';
import { CallerType, Order, OrderStatus } from 'base.f6st.com';

type OrderDetailDialogProps = {
  open: boolean;
  order: Order;
  onClose: () => void;
};

export const OrderDetailDialog: React.FC<OrderDetailDialogProps> = ({ open, order, onClose }) => {
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [editedLocation, setEditedLocation] = useState(order.qrCode.locationName);
  const [priority, setPriority] = useState(order.priority);
  const [applyToFuture, setApplyToFuture] = useState(false);
  const [orderStatus, setOrderStatus] = useState(order.orderStatus);
  const [fullRefund, setFullRefund] = useState(false);
  const customer = useAppContext().getCustomer();

  useEffect(() => {
    setEditedLocation(order.qrCode.locationName);
    setApplyToFuture(false);
  }, [order.qrCode.locationName]);

  const handleEditLocation = () => {
    setIsEditingLocation(true);
  };

  const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPriority(event.target.checked);
  };

  const handleOrderStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: OrderStatus) => {
    if (newStatus !== null) {
      setOrderStatus(newStatus);
      if (newStatus === OrderStatus.CANCELED) {
        setFullRefund(false); // Reset full refund checkbox when status changes to CANCELED
      }
    }
  };

  const handleDialogSave = async () => {
    let updateFields: { priority?: boolean, orderStatus?: OrderStatus, qrCodeLocation?: string } = {};
    let changes: string[] = [];

    if (priority !== order.priority) {
      updateFields.priority = priority;
      order.priority = priority;
      changes.push('priority');
    }

    if (editedLocation && editedLocation !== order.qrCode.locationName) {
      updateFields.qrCodeLocation = editedLocation;
      order.qrCode.locationName = editedLocation;
      changes.push('location');
      // await QrCodeDao.updateLocation(order.customerId, order.id, order.qrCodeId, editedLocation, applyToFuture);
    }

    if (orderStatus !== order.orderStatus) {
      updateFields.orderStatus = orderStatus;
      order.orderStatus = orderStatus;
      changes.push('status');
    }

    if (Object.keys(updateFields).length > 0) {
      await OrderClient.updateOrder(CallerType.ADMIN, order.id, order.customerId, updateFields);
      toast.success(`Order ${changes.join(', ')} updated successfully`);

      if (orderStatus === OrderStatus.CANCELED && fullRefund) {
        // Handle full refund logic here
        toast.success('Full refund processed successfully');
      }
    }

    onClose();
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => { });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Order Detail</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Order Time</TableCell>
              <TableCell>{new Date(order.date).toLocaleString()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Order Status</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', my: 1 }}>
                  <ToggleButtonGroup
                    color="warning"
                    size="small"
                    value={orderStatus}
                    exclusive
                    onChange={handleOrderStatusChange}
                    aria-label="Order Status"
                    sx={{
                      '& .MuiToggleButtonGroup-grouped': {
                        borderColor: 'black',
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: 'black',
                          backgroundColor: '#e84b4bee',
                          borderColor: 'black',
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        },
                      },
                    }}
                  >
                    {Object.values(OrderStatus).map((status) => (
                      <ToggleButton key={status} value={status}>
                        {status}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Box>
                {orderStatus === OrderStatus.CANCELED && (
                  <FormControlLabel
                    control={<Checkbox checked={fullRefund} onChange={(e) => setFullRefund(e.target.checked)} />}
                    label="Full refund"
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Priority Order</TableCell>
              <TableCell>
                <Switch checked={priority} onChange={handlePriorityChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Identifiers</TableCell>
              <TableCell>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography>Order ID:&nbsp;</Typography>
                    <Typography>{order.id}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.id)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography>Buyer ID:&nbsp;</Typography>
                    <Typography>{order.customerId}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.customerId)}>
                      <FileCopyIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography>QR ID:&nbsp;</Typography>
                    <Typography>{order.qrCode.id}</Typography>
                    <IconButton onClick={() => copyToClipboard(order.qrCode.id)}>
                      <FileCopyIcon />
                    </IconButton>
                    <IconButton component="a" href={`TODO`} target="_blank">
                      <WebIcon />
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>QR Location</TableCell>
              <TableCell>
                {isEditingLocation ? (
                  <>
                    <TextField value={editedLocation} onChange={(e) => setEditedLocation(e.target.value)} />
                    {editedLocation !== order.qrCode.locationName && (
                      <FormControlLabel
                        control={<Checkbox checked={applyToFuture} onChange={(e) => setApplyToFuture(e.target.checked)} />}
                        label="Save this location for this QR code"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {order.qrCode.locationName}
                    <IconButton onClick={handleEditLocation}>
                      <EditIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Payment Status</TableCell>
              <TableCell>{order.paymentStatus}</TableCell>
            </TableRow>
            {order.discountPercentage && order.discountPercentage > 0 && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Comeback Discount</TableCell>
                <TableCell>{order.discountPercentage}%</TableCell>
              </TableRow>
            )}
            {order.tipPercentage && order.tipPercentage > 0 && (
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Tip</TableCell>
                <TableCell>{order.tipPercentage}%</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Receipt</TableCell>
              <TableCell>
                <ReceiptLink order={order} customer={customer} businessReceipt={false} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
          <SecondaryButton text="Cancel" action={onClose} />
          <StandardButton text="Save" action={handleDialogSave} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import { Customer, Product, ProductsTexts, Products } from "../types/CustomerTypes.js";
import { AppError } from "./AppError.js";

export class ProductUtil {

    // find the id of the category of the given product 
    public static getProductCategoryId(customer: Customer, productId: string, languageCode?: string): string | undefined {
      if (!languageCode) {
        languageCode = customer.businessSettings.languageCodePrimary;
      }
  
      const products = customer.products;
  
      if (!products) {
        return undefined;
      }
  
      for (const category of products.categories) {
        if (category.products.some((product: Product) => product.id === productId)) {
          return category.id;
        }
      }
      return undefined;
    }
  
    public static getProductsTexts(customer: Customer, languageCode?: string): ProductsTexts {
      if (!languageCode || languageCode.trim().length == 0) {
        languageCode = customer.businessSettings.languageCodePrimary;
      }
      const productKey = `products_${languageCode}`;
      const products = customer[productKey] as ProductsTexts;
  
      if (!products) {
        throw new AppError("Products not found for language", languageCode);
      }
  
      return products;
    }
  
    public static removeProductsTexts(customer: Customer, languageCode: string): void {
      customer[`products_${languageCode}`] = undefined;
    }
    public static setProductsTexts(customer: Customer, languageCode: string, products: ProductsTexts): void {
      customer[`products_${languageCode}`] = products;
    }
  
    public static isProductsTexts(customer: Customer, languageCode: string): boolean {
      const productKey = `products_${languageCode}`;
      return !!customer[productKey];
    }
  
    public static isCategories(products: Products): boolean {
      return products.categories && products.categories.length > 0;
    }
  }
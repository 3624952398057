import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { Log } from 'base.f6st.com';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const startApp = async () => {
  try {
    Log.debug("Starting app ...");

    // Wait for i18n to initialize before rendering the app
    await i18n.init();

    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    );
    console.debug("App started successfully");
  } catch (error) {
    console.error("Failed to start app", error);
    root.render(
      <div>
        <h1>Failed to load the application</h1>
        <p>Please try refreshing the page or contact us.</p>
      </div>
    );
  }
};

startApp();
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Box,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryButton, StandardButton } from "common.f6st.com";

type DeleteConfirmDialog = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    content?: string;
};

export const DeleteConfirmDialog = ({
    open,
    onClose,
    onConfirm,
    title = "Are you sure?",
    content = "This action cannot be undone.",
}: DeleteConfirmDialog) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm" //
        >
            <DialogTitle>
                <Typography variant="h6" component="div">{title}</Typography>
                <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography>{content}</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
                    <SecondaryButton text="Cancel" action={onClose} />
                    <StandardButton text="Confirm" action={onConfirm} />
                </Box>
            </DialogActions>
        </Dialog>
    );
};
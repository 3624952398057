import { CallerType, User, Log } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class UserClient {



    public static async queryAllUsersByCustomerId(caller: CallerType, customerId: string): Promise<User[]> {
        Log.debug('Querying all users by customer ID from DynamoDB', { customerId });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            return await UserApi.queryAllUsersByCustomerId(customerId);
        } else {
            return await ApiUtil.postRequest<User[]>(caller, "user/queryAllUsersByCustomerId", { customerId });
        }
    }

    public static async putUser(caller: CallerType, user: User): Promise<void> {
        Log.debug('Creating or updating user in DynamoDB', user);
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            await UserApi.putUser(user);
        } else {
            await ApiUtil.postRequest<void>(caller, "user/putUser", user);
        }
    }

    public static async updateUserAdmin(caller: CallerType, userId: string, isAdmin: boolean): Promise<void> {
        Log.debug('Updating user admin status in DynamoDB', { userId, isAdmin });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            await UserApi.updateUserAdmin(userId, isAdmin);
        } else {
            await ApiUtil.postRequest<void>(caller, "user/updateUserAdmin", { userId, isAdmin });
        }
    }

    public static async updateUserDeactivated(caller: CallerType, userId: string): Promise<void> {
        Log.debug('Deactivating user in DynamoDB', { userId });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            await UserApi.updateUserDeactivated(userId);
        } else {
            await ApiUtil.postRequest<void>(caller, "user/updateUserDeactivated", { userId });
        }
    }

    public static async updatePassword(caller: CallerType, codeId: string, verificationCode: string, newPassword: string): Promise<boolean> {
        Log.debug('Updating user password', { codeId });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            return await UserApi.updatePassword(codeId, verificationCode, newPassword);
        } else {
            return await ApiUtil.postRequest<boolean>(caller, "user/updatePassword", { codeId, verificationCode, newPassword });
        }
    }

    public static async updateCustomerFirstLogin(caller: CallerType, id: string, value: string, email: string, name: string, password: string): Promise<boolean> {
        Log.debug('Updating customer first login', { id, email });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            return await UserApi.updateCustomerFirstLogin(id, value, email, name, password);
        } else {
            return await ApiUtil.postRequest<boolean>(caller, "user/updateCustomerFirstLogin", { id, value, email, name, password });
        }
    }

    public static async updateUserName(caller: CallerType, userId: string, customerId: string, newUserName: string): Promise<void> {
        Log.debug('Updating user name', { userId, newUserName });
        if (ApiUtil.isLocalApi()) {
            const UserApi = await this.getUserApi();
            await UserApi.updateUserName(userId, customerId, newUserName);
        } else {
            await ApiUtil.postRequest<void>(caller, "user/updateUserName", { userId, customerId, newUserName });
        }
    }

    public static async getUserApi() {
        // @ts-ignore: Dynamic import may not resolve during production build
        return (await import('api.f6st.com')).UserApi;
    }
}

import React, { useState, ChangeEvent } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, IconButton, TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { MailClient, SecondaryButton, StandardButton } from 'common.f6st.com'; // Adjust the import path as necessary
import { toast } from 'react-toastify';
import { CallerType } from 'base.f6st.com';

interface ForgotUserCodeDialogProps {
    open: boolean;
    onClose: () => void;
}

export const ForgotUserCodeDialog: React.FC<ForgotUserCodeDialogProps> = ({ open, onClose }) => {
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleSend = async () => {
        setError('');
        try {
            const emailExists = await MailClient.isEmailExists(CallerType.ADMIN,email.trim());
            if (!emailExists) {
                setError('Email does not exist.');
                return;
            }

        const subject = "F6ST user code";
        const body = "Your user code is: {code}";
            await MailClient.sendForgottenUserCode(CallerType.ADMIN, email.trim(), subject, body);
            toast.success("User code sent to your email");
            onClose();
        } catch (err) {
            setError('An error occurred while sending the email.');
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Typography variant="h6" align="center" component="div">Forgot User Code</Typography>
                <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
                {error && <Typography color="error">{error}</Typography>}
            </DialogContent>
            <DialogActions>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
                    <SecondaryButton text="Cancel" action={onClose} />
                    <StandardButton text="Send" action={handleSend} />
                </Box>
            </DialogActions>
        </Dialog>
    );
};
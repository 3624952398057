import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppContext } from '../AppProvider';
import { Product, Size, IDUtil, ProductExtra, ProductFilter, ProductCategory, I18nUtil, ProductsTextsUtil } from 'base.f6st.com';
import { SecondaryButton, StandardButton } from 'common.f6st.com';
import { useFormik } from 'formik';
import * as yup from 'yup';

type SizesDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
    txt: ProductsTextsUtil;
};

const sizeValidationSchema = yup.object({
    name: yup.string().required("Size name is required"),
    price: yup
        .number()
        .required("Price is required")
        .min(0, "Price must be 0 or greater"),
});

export const SizesDialog: React.FC<SizesDialogProps> = ({ open, onClose, onSave, product, txt }) => {
    const customer = useAppContext().getCustomer();
    const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
    const [prices, setPrices] = useState<Size[]>(product.sizes || []);

    useEffect(() => {
        if (open) {
            setPrices(product.sizes || []);
            formik.resetForm();
        }
    }, [open, product]);

    const formik = useFormik({
        initialValues: { name: "", price: "" },
        validationSchema: sizeValidationSchema,
        onSubmit: (values) => {
            const newId = IDUtil.getShortId();
            txt.set(newId, values.name);
            const newSizePrice = parseFloat(values.price);
            const newPriceEntry: Size = { id: newId, price: newSizePrice };
            const updatedPrices = [...prices, newPriceEntry];
            setPrices(updatedPrices);
            onSave({ ...product, sizes: updatedPrices });
            onClose();
        },
    });

    const handleClose = () => {
        setPrices(product.sizes || []);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h6" component="div">Add Sizes</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <TextField
                        id="size-name"
                        name="name"
                        label="Size Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        id="size-price"
                        name="price"
                        label={`Price (${currency})`}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </DialogContent>
                <DialogActions>
                    <SecondaryButton action={handleClose} text="Cancel" />
                    <StandardButton action={formik.handleSubmit} text="Add" />
                </DialogActions>
            </form>
        </Dialog>
    );
};

type AddExtraDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
    txt: ProductsTextsUtil;
};

const extraValidationSchema = yup.object({
    name: yup.string().required("Extra name is required"),
    price: yup
        .number()
        .required("Price is required")
        .min(0, "Price must be 0 or greater"),
});

export const AddExtraDialog: React.FC<AddExtraDialogProps> = ({ open, onClose, onSave, product, txt }) => {
    const customer = useAppContext().getCustomer();
    const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
    const [extras, setExtras] = useState<ProductExtra[]>(product.extras);

    useEffect(() => {
        if (open) {
            setExtras(product.extras);
            formik.resetForm();
        }
    }, [open, product]);

    const formik = useFormik({
        initialValues: { name: "", price: "" },
        validationSchema: extraValidationSchema,
        onSubmit: (values) => {
            const newId = IDUtil.getShortId();
            txt.set(newId, values.name);
            const newExtraPrice = parseFloat(values.price);
            const newExtraEntry: ProductExtra = { id: newId, price: newExtraPrice };
            const updatedExtras = [...extras, newExtraEntry];
            setExtras(updatedExtras);
            onSave({ ...product, extras: updatedExtras });
            onClose();
        },
    });

    const handleClose = () => {
        setExtras(product.extras);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant="h6" component="div">Add Extra</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <TextField
                        id="extra-name"
                        name="name"
                        label="Extra Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        id="extra-price"
                        name="price"
                        label={`Price (${currency})`}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="number"
                        inputProps={{ min: 0 }}
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.price && Boolean(formik.errors.price)}
                        helperText={formik.touched.price && formik.errors.price}
                    />
                </DialogContent>
                <DialogActions>
                    <SecondaryButton action={handleClose} text="Cancel" />
                    <StandardButton action={formik.handleSubmit} text="Add" />
                </DialogActions>
            </form>
        </Dialog>
    );
};


type ManageFiltersDialogProps = {
    open: boolean;
    onClose: () => void;
    onSave: (product: Product) => void;
    product: Product;
};

export const ManageFiltersDialog: React.FC<ManageFiltersDialogProps> = ({
    open = false,
    onClose = () => { },
    onSave = () => { },
    product = {} as Product,
}) => {
    const customer = useAppContext().getCustomer();
    const [showManage, setShowManage] = useState(false);
    const [productFilters, setProductFilters] = useState<ProductFilter[]>([]);
    const [allFilters, setAllFilters] = useState<ProductFilter[]>([]);
    const products = customer.products;

    useEffect(() => {
        if (open && product.filterIds) {
            const currentFilters = product.filterIds.map(id => products.filters.find((filter: ProductFilter) => filter.id === id) || { id });
            setProductFilters(currentFilters);
            setAllFilters(JSON.parse(JSON.stringify(products.filters)));
        }
    }, [open, product, products.filters]);

    const handleAdd = () => {
        const newId = IDUtil.getShortId();
        const inputElement = document.getElementById(`filter-name`) as HTMLInputElement;
        const newFilterName = inputElement ? inputElement.value : '';
        const newFilterEntry: ProductFilter = { id: newId };
        const updatedFilters = [...productFilters, newFilterEntry];

        if (customer && products.filters) {
            products.filters.push(newFilterEntry);
        }

        onSave({ ...product, filterIds: updatedFilters.map((filter: ProductFilter) => filter.id) });
        onClose();
    };

    const handleDelete = (id: string) => {
        const updatedFilters = allFilters.filter((filter: ProductFilter) => filter.id !== id);

        products.filters.splice(products.filters.findIndex((filter: ProductFilter) => filter.id === id), 1);

        products.categories.forEach((category: ProductCategory) => {
            category.products.forEach((product: Product) => {
                if (product.filterIds)
                    product.filterIds = product.filterIds.filter((filterId: string) => filterId !== id);
            });
        });
        setAllFilters(updatedFilters);
    };

    const handleSave = () => {
        if (customer) {
            products.filters = [...allFilters];
            setAllFilters(products.filters);
        }
        setShowManage(false);
        onClose();
    };

    const handleClose = () => {
        setShowManage(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl">
            <DialogTitle>
                <Typography variant="h6" component="div">{showManage ? "Manage Filters" : "Add Filter"}</Typography>
                <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            {!showManage && (
                <>
                    <DialogContent>
                        <TextField
                            id={`filter-name`}
                            label="Filter Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton action={handleClose} text="Cancel" />
                        <SecondaryButton action={() => setShowManage(true)} text="All Filters" />
                        <StandardButton action={handleAdd} text="Add" />
                    </DialogActions>
                </>
            )}
            {showManage && (
                <>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Filter Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allFilters.map((filter: ProductFilter) => (
                                    <TableRow key={`row-${filter.id}`}>
                                        <TableCell>
                                            <TextField
                                                id={`filter-name-${filter.id}`}
                                                fullWidth
                                                variant="outlined"
                                                defaultValue={filter.id}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDelete(filter.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton action={handleClose} text="Cancel" />
                        <StandardButton action={handleSave} text="Save" />
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

import { useState, useCallback, useEffect, useRef } from "react";
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Divider, Box } from "@mui/material";
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { Draggable, Droppable, DragDropContext, DropResult } from "@hello-pangea/dnd";
import { DeleteConfirmDialog } from "./dialogs/DeleteConfirmDialog";

interface DraggableListProps<T> {
    items: T[];
    title: string;
    onItemSelect: (item: T) => void;
    onItemDelete: (item: T) => void;
    onAddItem: () => void;
    onDragEnd: (updatedItems: T[]) => void;
    defaultSelectedId: string; // New prop for default selected ID
}

export const DraggableList = <T extends { id: string, name: string }>({
    items,
    title,
    onItemSelect,
    onItemDelete,
    onAddItem,
    onDragEnd,
    defaultSelectedId
}: DraggableListProps<T>) => {
    const [selectedItem, setSelectedItem] = useState<T | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<T | null>(null);

    // Flag to track the first render
    const isFirstRender = useRef(true);

    // State to trigger controlled re-renders
    const [renderState, setRenderState] = useState(0);

    // Trigger re-render explicitly
    const triggerRender = useCallback(() => {
        setRenderState((prev) => prev + 1);
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            // Set default selection based on prop for first render only
            const defaultItem = items.find(item => item.id === defaultSelectedId);
            if (defaultItem) {
                setSelectedItem(defaultItem);
            }
            isFirstRender.current = false;  // After the first render, don't set default again
        }
    }, [items, defaultSelectedId]);

    const handleSelect = useCallback((item: T) => {
        setSelectedItem(item);
        onItemSelect(item);
        triggerRender();
    }, [onItemSelect, triggerRender]);

    const handleDelete = useCallback((item: T) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
        triggerRender();
    }, [triggerRender]);

    const confirmDelete = useCallback(() => {
        if (itemToDelete) {
            onItemDelete(itemToDelete);
            setItemToDelete(null);
            setDeleteDialogOpen(false);
            triggerRender();
        }
    }, [itemToDelete, onItemDelete, triggerRender]);

    const handleDragEnd = useCallback((result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        const updatedItems = Array.from(items);
        const [movedItem] = updatedItems.splice(source.index, 1);
        updatedItems.splice(destination.index, 0, movedItem);
        onDragEnd(updatedItems);
        triggerRender();
    }, [items, onDragEnd, triggerRender]);

    return (
        <Box sx={{ border: 1, borderColor: "grey.300", p: 2, borderRadius: 1 }}>
            <Typography variant="h6" display="flex" justifyContent="space-between" alignItems="center">
                {title}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={title}>
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => handleSelect(item)}
                                            sx={{
                                                backgroundColor:
                                                    selectedItem?.id === item.id ? "rgba(0, 0, 0, 0.3)" : "transparent",
                                                "&:hover": { backgroundColor: "rgba(100, 100, 100, 0.1)" },
                                            }}
                                        >
                                            <ListItemText primary={item.name} />
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDelete(item);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext>
            <IconButton onClick={onAddItem} style={{ width: "40px" }}>
                <AddIcon />
            </IconButton>
            <DeleteConfirmDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                onConfirm={confirmDelete}
            />
        </Box>
    );
};

import { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  Paper,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Alert
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  SecondaryButton,
  StandardButton,
  StandardSwitch,
  UserClient
} from "common.f6st.com";
import { toast } from "react-toastify";
import { useAppContext } from "../components/AppProvider";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { DeleteConfirmDialog } from "../components/dialogs/DeleteConfirmDialog";
import { CustomAppBar } from "../components/CustomAppBar";
import { User, IDUtil, CallerType } from "base.f6st.com";

// Define the validation schema using yup
const validationSchema = yup.object({
  name: yup.string().required("Name is required")
});

type UsersPageProps = {};

export const UsersPage = ({ }: UsersPageProps) => {
  const customer = useAppContext().getCustomer();

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [isNewItem, setIsNewItem] = useState(false);
  const [items, setItems] = useState<User[]>([]);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        const user: User = {
          id: IDUtil.getShortId(),
          customerId: customer.id,
          email: "-", // email index requires a value to be set
          name: data.name,
          admin: false,
          active: true
        };

        await UserClient.putUser(CallerType.ADMIN, user);
        toast.success("User saved successfully.");
        await loadItems();
        resetForm();
        setIsNewItem(false);
      } catch {
        toast.error("Error saving user.");
      }
    }
  });

  const loadItems = async () => {
    try {
      const loadedItems = await UserClient.queryAllUsersByCustomerId(CallerType.ADMIN, customer.id);
      setItems(loadedItems);
    } catch {
      toast.error("Error loading users.");
    }
  };

  useEffect(() => {
    loadItems();
  }, [customer]);

  const handleDelete = async () => {
    if (userToDelete) {
      await UserClient.updateUserDeactivated(CallerType.ADMIN, userToDelete);
      toast.success("User deactivated successfully.");
      await loadItems();
      setConfirmDeleteDialogOpen(false);
      setUserToDelete(null);
    }
  };

  const handleNew = () => {
    formik.resetForm();
    setIsNewItem(true);
  };

  const formatDate = (date?: Date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat(navigator.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric"
    }).format(new Date(date));
  };

  const handleAdminChange = async (user: User, admin: boolean) => {
    const loggedInAdminCount = items.filter(u => u.admin && u.lastLogin).length;
    const userHasLoggedIn = !!user.lastLogin;

    if (!admin && userHasLoggedIn && loggedInAdminCount <= 1) {
      toast.error(
        "There must be at least one admin user who has logged in at least once."
      );
      return;
    }

    try {
      await UserClient.updateUserAdmin(CallerType.ADMIN, user.id, admin);
      toast.success("User updated successfully.");
      await loadItems();
    } catch {
      toast.error("Error updating user.");
    }
  };

  const handleDeleteClick = (userId: string) => {
    const loggedInAdminCount = items.filter(u => u.admin && u.lastLogin).length;
    const userToBeDeleted = items.find(u => u.id === userId);
    const userIsLoggedInAdmin = userToBeDeleted?.admin && userToBeDeleted?.lastLogin;

    if (loggedInAdminCount <= 1 && userIsLoggedInAdmin) {
      toast.error(
        "There must be at least one admin user who has logged in at least once."
      );
      return;
    }
    setUserToDelete(userId);
    setConfirmDeleteDialogOpen(true);
  };

  return (
    <>
      <CustomAppBar backButton pageTitle="Users" />
      <Container>
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>User Code</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>
                    <StandardSwitch
                      checked={item.admin}
                      onChange={(e) => handleAdminChange(item, e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>{formatDate(item.lastLogin)}</TableCell>
                  <TableCell>
                    {(items.filter(u => u.admin && u.lastLogin).length > 1 || !item.admin || !item.lastLogin) && (
                      <IconButton onClick={() => handleDeleteClick(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <DeleteConfirmDialog
                      open={confirmDeleteDialogOpen}
                      onClose={() => setConfirmDeleteDialogOpen(false)}
                      onConfirm={handleDelete}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {isNewItem && (
            <FormControl
              component="form"
              onSubmit={formik.handleSubmit}
              fullWidth
              sx={{ mt: 4 }}
            >
              <TextField
                label="Name of the new user"
                variant="outlined"
                fullWidth
                margin="normal"
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                {...formik.getFieldProps("name")}
              />

              <Alert severity="info" sx={{ mt: 2 }}>
                Give the new user their User Code.
                <br />
                They must visit f6st.com, click Login, and enter the code.
              </Alert>

              <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                <StandardButton text="Save" action={formik.handleSubmit} />
                <SecondaryButton
                  text="Cancel"
                  action={() => {
                    setIsNewItem(false);
                    formik.resetForm();
                  }}
                />
              </Stack>
            </FormControl>
          )}

          {!isNewItem && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
              <StandardButton text="New User" action={handleNew} />
            </Stack>
          )}
        </Paper>
      </Container>
    </>
  );
};

import { Log } from './Log.js';

class AppError extends Error {
  public innerError?: Error;

  /**
   * Creates an instance of AppError.
   * @param message - The error message.
   * @param innerError - The optional inner error.
   */
  constructor(message: string, innerError?: any) {
    super(message);
    this.name = this.constructor.name;
    this.innerError = innerError;

    // Log the error when the AppError is created
    Log.error(this.getFormattedError(message, innerError));

    // Ensure the prototype chain is correctly set up
    Object.setPrototypeOf(this, AppError.prototype);
  }

  /**
   * Formats the error message and stack trace for logging.
   * @param message - The error message.
   * @param innerError - The optional inner error.
   * @returns The formatted error message and stack trace.
   */
  private getFormattedError(message: string, innerError?: any): string {
    let errorDetails = `AppError: ${message}`;

    if (innerError) {
      errorDetails += `\nInner Error: ${innerError.message || innerError}`;

      if (innerError instanceof Error && innerError.stack) {
        errorDetails += `\nInner Error Stack:\n${innerError.stack}`;
      }
    }

    if (this.stack) {
      errorDetails += `\nAppError Stack:\n${this.stack}`;
    }

    return errorDetails;
  }
}

export { AppError };

// SecondaryButton.tsx
import { Box, IconButton } from "@mui/material";

type SecondaryButtonProps = {
  text: string; // This will now be the i18n key
  action: (e: any) => void;
  disabled?: boolean;
  style?: {};
};


export function SecondaryButton({ text, action, disabled, style }: SecondaryButtonProps) {

  return (
    <Box display="flex" justifyContent="center">
      <IconButton
        sx={{
          color: '#172339',
          fontSize: 18,
          fontWeight: 700,
          border: '1px solid #172339',
          padding: 0,
          margin: 0,
          borderRadius: '36px',
          width: '200px',
          height: '40px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            backgroundColor: '#172339',
            color: '#faf8f6',
          },
          ...style,
        }}
        disabled={disabled}
        onClick={(e) => action(e)}
      >
        {(text)}
      </IconButton>
    </Box>
  );
}

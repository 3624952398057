import React, { useState, ChangeEvent } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Box
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryButton, StandardButton, UserClient } from 'common.f6st.com';
import { toast } from 'react-toastify';
import { CallerType, User } from 'base.f6st.com';

interface UserNameChangeDialogProps {
  open: boolean;
  onClose: () => void;
  user: User;
  onSave: (data: { name: string }) => void;
}

export const UserNameChangeDialog: React.FC<UserNameChangeDialogProps> = ({ open, onClose, user, onSave }) => {
  const [name, setName] = useState<string>(user.name);
  const [error, setError] = useState<string | null>(null);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSave = async () => {
    try {
      await UserClient.updateUserName(CallerType.ADMIN, user.id, user.customerId, name);
      onSave({ name });
      onClose();
      toast.success("User name changed successfully");
    } catch (err) {
      setError("Failed to change username. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Change name
        <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          label="New Username"
          type="text"
          fullWidth
          sx={{ mt: 1 }}
          value={name}
          onChange={handleNameChange}
          error={Boolean(error)}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
          <SecondaryButton text="Cancel" action={onClose} />
          <StandardButton text="Save" action={handleSave} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import { Log } from "base.f6st.com";

export class Config {

  public static readonly API_URL: string = "https://admin.f6st.com/api";
  public static readonly MAX_PRODUCT_ORDER_QUANTITY: number = 50;
  public static readonly APPLICATION_FEE_PERCENTAGE: number = 1;
  public static readonly GOOGLE_ANALYTICS_TRACKING_ID: string = "G-0X9WL9ZN33";
  public static readonly GOOGLE_MAPS_API_KEY: string = "AIzaSyAOEQv8QH2AhY2LUSBsXUD89Swme78XZLY";
  public static readonly IP_INFO_KEY_FOR_COUNTRY_DETECTION: string = "50e8d60b98ee30";

  public static getStripePublicKey(): string {
      return "pk_test_51PSd8400XyzykPA4TtuXy7GCCgYMS4we7ytogcqvdQAOPo8NJfFNbNyitnoUYp1iOdwaZoME5HxxFLK4m5ichKkF00DaborGrU";
  }

  public static getStripeSecretKey(): string {
      return "sk_test_51PSd8400XyzykPA4DLWn5hgdXNni4vdjXgDHXoRoHPCMXTabvLQ8UG7q2zBR1SIgFjWMlaLNdWFmd174RG3xHXqc00NuiVfHSr";
  }

  public static getImageUrl(customerId: string, imageId: string | undefined): string {
    if (!imageId || imageId.trim().length == 0) return "/assets/noimage.webp"
    const res = `https://static.f6st.com/${customerId}/${imageId}.webp`;
    return res;
  }

  public static getPaymentRedirectUrl(): string {
    return "https://admin.f6st.com/qr-codes";
  }

}

import { createContext, useContext, useState, useEffect, ReactNode, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderDate, OrderFilter } from './order/OrderFilterDrawer';
import { AppError, CallerType, Customer, Log, Order, OrderStatus, User } from 'base.f6st.com';
import { CustomerClient, LoginClient, LoginUtil } from 'common.f6st.com';

type AppContextType = {
  login: (userCode: string, password: string) => Promise<void>;
  loginRoot: (userName: string, password: string, customerId: string) => Promise<void>; // Added loginRoot method
  logout: () => void;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | undefined>>;
  getCustomer: () => Customer;
  getLoggedInUser: () => User | undefined;
  loading: boolean;
  orderFilter: OrderFilter;
  setOrderFilter: React.Dispatch<React.SetStateAction<OrderFilter>>;
  orders: Order[];
  setOrders: React.Dispatch<React.SetStateAction<Order[]>>;
  loadCustomer: (customerId: string, languageCodePrimary: string) => Promise<Customer>;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [loggedInUser, setLoggedInUser] = useState<User | undefined>(undefined);
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [orderFilter, setOrderFilter] = useState<OrderFilter>({
    orderStatus: OrderStatus.NEW,
    teamId: undefined,
    qrCodeLocations: [],
    orderDate: OrderDate.LAST_1H,
    categoryId: '',
    productId: '',
    orderId: '',
    qrCodeId: '',
    buyerId: '',
    paymentStatus: '',
  });

  const loadCustomer = async (customerId: string, languageCode?: string): Promise<Customer> => {
    const customer = await CustomerClient.getById(CallerType.ADMIN, customerId, languageCode);
    if (!customer) throw new AppError("Customer is not initialized");
    setCustomer(customer);
    return customer;
  };

  const login = async (userId: string, password: string): Promise<void> => {
    const user = await LoginClient.loginAdmin(userId.trim(), password.trim());
    if (user) {
      await loadCustomer(user.customerId);
      setLoggedInUser(user);
    } else {
      throw new Error('Invalid credentials');
    }
  };

  const loginRoot = async (userName: string, password: string, customerId: string): Promise<void> => {
    const user = await LoginClient.loginAdminRoot(userName.trim(), password.trim());
    if (user) {
      await loadCustomer(customerId);
      setLoggedInUser(user);
    } else {
      throw new Error('Invalid credentials');
    }
  };

  useEffect(() => {
      setLoading(false); // Set loading to false after data fetching
  }, []);

  const logout = (): void => {
    LoginUtil.removeAuthenticationToken(CallerType.ADMIN);
    Log.debug("Logging out");
    setLoggedInUser(undefined);
    setCustomer(undefined);
    navigate('/login');
  };

  const getCustomer = (): Customer => {
    if (!customer) {
      throw new AppError('Customer is not available');
    }
    return customer;
  };

  const getLoggedInUser = (): User | undefined => {
    if (!loggedInUser || !LoginUtil.isAuthenticationToken(CallerType.ADMIN)) {
      return undefined;
    }
    return loggedInUser;
  };

  return (
    <AppContext.Provider value={{ 
      login, 
      loginRoot,  // Include loginRoot in the context provider
      logout, 
      setCustomer, 
      getCustomer, 
      getLoggedInUser, 
      loading, 
      orderFilter, 
      setOrderFilter, 
      orders, 
      setOrders, 
      loadCustomer 
    }}>
      {children}
    </AppContext.Provider>
  );
};

import { MouseEvent } from 'react';
import { Card, CardContent, CardMedia, Grid2, Typography } from '@mui/material';
import {
  Product,
  OrderItem,
  ProductsTextsUtil,
  IDUtil,
  OrderUtil,
  AppError,
  I18nUtil,
  Customer,
} from 'base.f6st.com';
import { StandardButton } from './StandardButton';
import { Config } from '../util/Config';

/**
 * Usage enumerations for the ProductCard
 */
export enum CardUseCase {
  HOMEPAGE,
  BASKETPAGE,
  ORDERPAGE,
  ADMIN,
}

type ProductCardProps = {
  product: Product;
  categoryId: string;
  useCase: CardUseCase;
  customer: Customer;
  orderItem?: OrderItem;
  txt: ProductsTextsUtil;
  onBasketChange?: (orderItem: OrderItem) => void;
};

export const ProductCard = ({
  product,
  categoryId,
  useCase,
  customer,
  orderItem,
  txt,
  onBasketChange,
}: ProductCardProps) => {
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);

  // Construct currentOrderItem
  let currentOrderItem: OrderItem;

  if ((useCase === CardUseCase.HOMEPAGE || useCase === CardUseCase.ADMIN) && product) {
    currentOrderItem = {
      id: IDUtil.getShortId(),
      product: product,
      categoryId: categoryId,
      quantity: 1,
      extraIds: [],
      vatPercentage: OrderUtil.getProductVATPercentage(product, customer),
    };

    if (product.sizes && product.sizes.length > 0) {
      currentOrderItem.sizeId = product.sizes[0].id;
    }
  } else if (orderItem) {
    currentOrderItem = orderItem;
  } else {
    throw new AppError('Invalid parameters for ProductCard component');
  }

  // Pricing & selection
  const productPriceString = OrderUtil.getProductTotalAmount(
    currentOrderItem.product,
    currency,
    currentOrderItem.sizeId
  ).amountString;

  const hasMultipleSizes =
    currentOrderItem.sizeId && product.sizes && product.sizes.length > 0;

  const selectedSize = hasMultipleSizes
    ? product.sizes?.find((size) => size.id === currentOrderItem.sizeId)
    : undefined;

  const selectedExtras = currentOrderItem.extraIds
    ? currentOrderItem.extraIds.map((extraId) =>
      product.extras.find((extra) => extra.id === extraId)
    )
    : [];

  // Handlers
  const handleAction = (e: MouseEvent) => {
    e.preventDefault();
    if (onBasketChange) onBasketChange(currentOrderItem);
  };

  // Return the appropriate action button for each use-case
  const getActionButton = () => {
    switch (useCase) {
      case CardUseCase.HOMEPAGE:
        return (
          <StandardButton
            text="+"
            action={handleAction}
            style={{
              position: 'absolute',
              top: '-8px',
              right: '-8px',
              width: '40px',
              height: '40px',
              borderRadius: '36px',
            }}
          />
        );
      case CardUseCase.BASKETPAGE:
        return (
          <StandardButton
            text="-"
            action={handleAction}
            style={{
              position: 'absolute',
              top: '-8px',
              right: '-8px',
              width: '40px',
              height: '40px',
              borderRadius: '36px',
            }}
          />
        );
      case CardUseCase.ADMIN:
        return null;
      default:
        return null;
    }
  };

  return (
    <Grid2>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <CardContent sx={{ display: 'flex', flexDirection: 'column', minWidth: '210px' }}>
          <Typography component="div" variant="h5" fontSize={16}>
            {useCase === CardUseCase.HOMEPAGE || useCase === CardUseCase.ADMIN
              ? txt.get(product.id)
              : `${currentOrderItem.quantity} x ${txt.get(product.id)}`}
          </Typography>

          {useCase === CardUseCase.HOMEPAGE || useCase === CardUseCase.ADMIN ? (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              fontSize={10}
              maxHeight="70px"
              maxWidth="190px"
              sx={{ overflow: 'hidden' }}
            >
              {txt.get(product.descriptionId)}
            </Typography>
          ) : (
            <Typography variant="subtitle1" color="text.secondary">
              {selectedSize ? txt.get(selectedSize.id) : ''}
              {selectedExtras && selectedExtras.length > 0 && ', '}
              {selectedExtras
                .map((extra, index) =>
                  extra ? `${index > 0 ? ', ' : ''} + ${txt.get(extra.id)}` : ''
                )
                .join('')}
            </Typography>
          )}

          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{
              marginTop: 'auto',
              mb: -1,
            }}
          >
            {(useCase === CardUseCase.HOMEPAGE || useCase === CardUseCase.ADMIN) &&
              product.sizes &&
              product.sizes.length > 0
              ? `${product.sizes[0].price}-${product.sizes[product.sizes.length - 1].price}${currency}`
              : productPriceString}
          </Typography>
        </CardContent>

        <CardMedia
          component="img"
          sx={{
            width: { xs: '100%', sm: 140 },
            height: { xs: 140, sm: 140 },
            borderRadius: '5px',
            ml: -5,
            objectFit: 'cover',
            marginTop: { xs: 2, sm: 0 },
          }}
          image={Config.getImageUrl(customer.id, product.imgId)}
        />

        {getActionButton()}
      </Card>
    </Grid2>
  );
};

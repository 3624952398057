import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import the translation JSON files
import de from './i18n/de.json';
import el from './i18n/el.json';
import en from './i18n/en.json';
import es from './i18n/es.json';
import fi from './i18n/fi.json';
import fr from './i18n/fr.json';
import hu from './i18n/hu.json';
import it from './i18n/it.json';
import ja from './i18n/ja.json';
import ko from './i18n/ko.json';
import nl from './i18n/nl.json';
import pl from './i18n/pl.json';
import ptPT from './i18n/pt-PT.json';
import ro from './i18n/ro.json';
import ru from './i18n/ru.json';
import sk from './i18n/sk.json';
import sv from './i18n/sv.json';
import tr from './i18n/tr.json';
import uk from './i18n/uk.json';
import zh from './i18n/zh.json';

// Define the resources object
const resources = {
  de: { translation: de },
  el: { translation: el },
  en: { translation: en },
  es: { translation: es },
  fi: { translation: fi },
  fr: { translation: fr },
  hu: { translation: hu },
  it: { translation: it },
  ja: { translation: ja },
  ko: { translation: ko },
  nl: { translation: nl },
  pl: { translation: pl },
  'pt-PT': { translation: ptPT },
  ro: { translation: ro },
  ru: { translation: ru },
  sk: { translation: sk },
  sv: { translation: sv },
  tr: { translation: tr },
  uk: { translation: uk },
  zh: { translation: zh },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
import { QrCode, Log } from 'base.f6st.com';
import { PDFDocument, rgb, StandardFonts, PDFFont, PDFPage } from 'pdf-lib';
import QRCode from 'qrcode';

export class QRPDFUtil {
    static async generatePDFs(
        headerText: string,
        footerText: string,
        numPages: number,
        showHeaderText: boolean,
        showFooterText: boolean,
        backgroundColor: string,
        qrCodeColor: string,
        headerAndFooterBackgroundColor: string,
        headerAndFooterFontColor: string,
        fontFamily: StandardFonts,
        qrCode: QrCode
    ): Promise<{ pdfBytes: Uint8Array }> {
        const pdfDoc = await PDFDocument.create();
        const totalCodes = numPages;

        const font = await pdfDoc.embedFont(fontFamily);

        const hexToRgb = (hex: string): [number, number, number] => {
            const bigint = parseInt(hex.slice(1), 16);
            const r = (bigint >> 16) & 255;
            const g = (bigint >> 8) & 255;
            const b = bigint & 255;
            return [r / 255, g / 255, b / 255];
        };

        for (let i = 0; i < totalCodes; i++) {
            const page = pdfDoc.addPage([600, 800]);

            // Draw the full middle section background color
            page.drawRectangle({
                x: 0,
                y: 0,
                width: page.getWidth(),
                height: page.getHeight(),
                color: rgb(...hexToRgb(backgroundColor)),
            });

            const headerFooterHeight = page.getHeight() * 0.15;
            let availableHeight = page.getHeight() * 0.7;
            if (showHeaderText && !showFooterText) {
                availableHeight = page.getHeight() * 0.55;
            } else if (!showHeaderText && showFooterText) {
                availableHeight = page.getHeight() * 1.15;
            }

            let headerFontSize = calculateFontSize(page, headerText, font, page.getWidth() * 0.95, headerFooterHeight);
            let footerFontSize = calculateFontSize(page, footerText, font, page.getWidth() * 0.95, headerFooterHeight);

            // Use the smaller font size for both header and footer
            const commonFontSize = Math.min(headerFontSize, footerFontSize);

            if (showHeaderText) {
                const headerTextWidth = font.widthOfTextAtSize(headerText, commonFontSize);
                const headerX = (page.getWidth() - headerTextWidth) / 2;

                page.drawRectangle({
                    x: 0,
                    y: page.getHeight() - headerFooterHeight,
                    width: page.getWidth(),
                    height: headerFooterHeight,
                    color: rgb(...hexToRgb(headerAndFooterBackgroundColor)),
                });

                const headerTextY = page.getHeight() - headerFooterHeight / 2 - commonFontSize / 4;

                page.drawText(headerText, {
                    x: headerX,
                    y: headerTextY,
                    size: commonFontSize,
                    font,
                    color: rgb(...hexToRgb(headerAndFooterFontColor)),
                });
            }

            if (showFooterText) {
                const footerTextWidth = font.widthOfTextAtSize(footerText, commonFontSize);
                const footerX = (page.getWidth() - footerTextWidth) / 2;

                page.drawRectangle({
                    x: 0,
                    y: 0,
                    width: page.getWidth(),
                    height: headerFooterHeight,
                    color: rgb(...hexToRgb(headerAndFooterBackgroundColor)),
                });

                const footerTextY = headerFooterHeight / 2 - commonFontSize / 4;

                page.drawText(footerText, {
                    x: footerX,
                    y: footerTextY,
                    size: commonFontSize,
                    font,
                    color: rgb(...hexToRgb(headerAndFooterFontColor)),
                });
            }

            // Generate QR code URL
            const url = constructQRCodeURL(qrCode);
            Log.debug("QR code URL", url);
            const qrCodeDataUrl = await QRCode.toDataURL(url, {
                color: {
                    dark: qrCodeColor,  // QR code color
                    light: backgroundColor  // Background color
                }
            });
            const pngImageBytes = await fetch(qrCodeDataUrl).then(res => res.arrayBuffer());
            const pngImage = await pdfDoc.embedPng(pngImageBytes);

            // Calculate QR code dimensions and position
            const qrCodeDimension = Math.min(availableHeight, page.getWidth());
            let qrCodeYPosition;

            if (showHeaderText && showFooterText) {
                qrCodeYPosition = headerFooterHeight + (availableHeight - qrCodeDimension) / 2;
            } else if (showHeaderText) {
                qrCodeYPosition = headerFooterHeight + (availableHeight - qrCodeDimension) / 2;
            } else if (showFooterText) {
                qrCodeYPosition = (availableHeight - qrCodeDimension) / 2;
            } else {
                qrCodeYPosition = (page.getHeight() - qrCodeDimension) / 2;
            }

            const qrCodeXPosition = (page.getWidth() - qrCodeDimension) / 2;

            page.drawImage(pngImage, {
                x: qrCodeXPosition,
                y: qrCodeYPosition,
                width: qrCodeDimension,
                height: qrCodeDimension,
            });
        }

        const pdfBytes = await pdfDoc.save();
        return { pdfBytes };
    }
}

// Helper function to construct the QR code URL with proper encoding
export function constructQRCodeURL(qrCode: QrCode): string {
    return `https://order.f6st.com?c=${qrCode.id}:${qrCode.customerId}:${qrCode.locationType}`;
}

// Helper function to calculate font size to fit text within a given width and height
function calculateFontSize(page: PDFPage, text: string, font: PDFFont, maxWidth: number, maxHeight: number): number {
    let fontSize = 100;
    while ((font.widthOfTextAtSize(text, fontSize) > maxWidth || font.heightAtSize(fontSize) > maxHeight) && fontSize > 1) {
        fontSize -= 1;
    }
    return fontSize;
}

// DeliverySettingsDialog.tsx

import React, { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  FormControlLabel,
  Typography
} from "@mui/material";
import { Close as CloseIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { StandardButton, SecondaryButton, AddressSelection, StandardSwitch } from "common.f6st.com";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import { BusinessSettings, DeliverySettings, I18nUtil, MetricSystem } from 'base.f6st.com';

// Helper function to get the first error message from an errors object
const getFirstError = (errors: any): string => {
  if (typeof errors !== 'object' || errors === null) return '';
  const errorValues = Object.values(errors);
  return errorValues.find(error => typeof error === 'string') || '';
};

const createDeliveryValidationSchema = (countryCode: string) => {
  const zipCodeRegex = countryCode === "US" ? /^[0-9]{5}(?:-[0-9]{4})?$/ : /^[A-Za-z0-9\s-]+$/; // Example for US and generic for others
  
  return Yup.object().shape({
    maxDistance: Yup.number()
      .required("Maximum Delivery Distance is required.")
      .min(1, "Distance cannot be less than 1.")
      .max(99, "Distance cannot be more than 99.")
      .typeError("Maximum Delivery Distance must be a number."),
    minimumOrderAmount: Yup.number()
      .typeError("Minimum Order Amount must be a number."),
    freeDeliveryAmountLimit: Yup.number()
      .typeError("Free Delivery Limit must be a number."),
    deliveryFee: Yup.number()
      .nullable()
      .typeError("Delivery Fee must be a number."),
    excludedZips: Yup.array()
      .of(Yup.string()
        .matches(zipCodeRegex, "Invalid Zip Code format.")
        .required("Zip Code is required."))
      .nullable(),
    maxDistanceAddress: Yup.object().shape({
      placeId: Yup.string().required("No proper address is set."),
      city: Yup.string().required("City is required."),
      street: Yup.string().required("Street is required."),
      zip: Yup.string().required("Zip Code is required."),
      lat: Yup.number(),
      lng: Yup.number(),
    }),
  });
};

interface DeliverySettingsDialogProps {
  open: boolean;
  onClose: () => void;
  businessSettings: BusinessSettings;
}

export const DeliverySettingsDialog = ({ open, onClose, businessSettings }: DeliverySettingsDialogProps) => {
  const currencyCode = I18nUtil.getDisplayableCurrency(businessSettings.countryCode);
  const metricsSystem = I18nUtil.getDistanceMetricSystem(businessSettings.countryCode);
  const metricsLabel = metricsSystem === MetricSystem.KM ? "km" : "mi";

  const initialValues: DeliverySettings = businessSettings.delivery || {
    maxDistance: 0,
    minimumOrderAmount: undefined,
    freeDeliveryAmountLimit: undefined,
    deliveryFee: undefined,
    excludedZips: [],
    maxDistanceAddress: businessSettings.address,
  };

  const [originalValues, setOriginalValues] = useState(initialValues);

  const formik = useFormik({
    initialValues,
    validationSchema: createDeliveryValidationSchema(businessSettings.countryCode),
    validateOnChange: true, // Enable validate on change
    validateOnBlur: true, // Enable validate on blur
    onSubmit: (values) => {
      const trimmedValues = {
        ...values,
        excludedZips: values.excludedZips?.map((zip: string) => zip.trim())
      };
      businessSettings.delivery = trimmedValues;
      setOriginalValues(trimmedValues);
      onClose();
    }
  });

  const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const newZipCodes = [...(formik.values.excludedZips || [])];
    newZipCodes[index] = event.target.value;
    formik.setFieldValue("excludedZips", newZipCodes);
  };

  const addZipCode = () => {
    const newZipCodes = [...(formik.values.excludedZips || []), ""];
    formik.setFieldValue("excludedZips", newZipCodes);
  };

  const removeZipCode = (index: number) => {
    const newZipCodes = [...(formik.values.excludedZips || [])];
    newZipCodes.splice(index, 1);
    formik.setFieldValue("excludedZips", newZipCodes);
  };

  const handleClose = () => {
    formik.setValues(originalValues);
    onClose();
  };

  const handleSave = async () => {
    const errors = await formik.validateForm(); // Validate the form
    if (Object.keys(errors).length === 0) {
      formik.handleSubmit();
    } else {
      formik.setTouched({
        maxDistance: true,
        maxDistanceAddress: {
          placeId: true,
          city: true,
          street: true,
          zip: true,
          lat: true,
          lng: true,
        },
        minimumOrderAmount: true,
        freeDeliveryAmountLimit: true,
        deliveryFee: true,
      });
    }
  };

  const handleAddressSelection = (field: string, value: any) => {
    formik.setFieldValue(field, value);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Delivery Settings
        <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="body1" sx={{ marginRight: 2, minWidth: '250px' }}>
              Maximum Delivery Distance ({metricsLabel})
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              {...formik.getFieldProps("maxDistance")}
              inputProps={{ min: 1, max: 99 }}
              error={Boolean(formik.touched.maxDistance && formik.errors.maxDistance)}
              helperText={formik.touched.maxDistance && formik.errors.maxDistance ? String(formik.errors.maxDistance) : ''}
            />
          </Box>
          <Box mb={1}>
            <AddressSelection
              countryCode={businessSettings.countryCode}
              label="Address for the Maximum Distance Calculation"
              defaultPlaceId={formik.values.maxDistanceAddress?.placeId}
              fieldName="maxDistanceAddress"
              setFieldValue={handleAddressSelection}
            />
            {/* Display only the first error message for 'maxDistanceAddress' */}
            {formik.touched.maxDistanceAddress && formik.errors.maxDistanceAddress && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }} role="alert" aria-live="assertive">
                {getFirstError(formik.errors.maxDistanceAddress)}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <Box display="flex" alignItems="center" gap={2} sx={{ width: '100%' }}>
              <FormControlLabel
                control={
                  <Field
                    name="excludedZips"
                    type="checkbox"
                    as={StandardSwitch}
                    checked={formik.values.excludedZips && formik.values.excludedZips.length > 0}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.checked) {
                        formik.setFieldValue("excludedZips", [""]);
                      } else {
                        formik.setFieldValue("excludedZips", []);
                      }
                    }}
                  />
                }
                label="Excluded ZIP codes"
              />
            </Box>
          </Box>
          {(formik.values.excludedZips || []).map((zipCode: string, index: number) => {
            const error = formik.errors.excludedZips && Array.isArray(formik.errors.excludedZips) ? formik.errors.excludedZips[index] : undefined;
            return (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <TextField
                  fullWidth
                  label={`Zip Code ${index + 1}`}
                  variant="outlined"
                  value={zipCode}
                  onChange={(e) => handleZipCodeChange(e, index)}
                  error={Boolean(error)}
                  helperText={error ? String(error) : ''}
                />
                <IconButton onClick={() => removeZipCode(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          })}
          {formik.values.excludedZips && (
            <SecondaryButton text="Add Zip Code" action={addZipCode} />
          )}

          <Box mt={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <FormControlLabel
                control={
                  <Field
                    name="minimumOrderAmount"
                    type="checkbox"
                    as={StandardSwitch}
                    checked={formik.values.minimumOrderAmount !== undefined}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("minimumOrderAmount", event.target.checked ? 0 : undefined);
                    }}
                  />
                }
                label={`Minimum Order Amount (${currencyCode})`}
                sx={{ width: '50%' }}
              />
              {formik.values.minimumOrderAmount !== undefined && (
                <TextField
                  variant="outlined"
                  type="number"
                  {...formik.getFieldProps("minimumOrderAmount")}
                  inputProps={{ min: 0 }}
                  error={Boolean(formik.touched.minimumOrderAmount && formik.errors.minimumOrderAmount)}
                  helperText={formik.touched.minimumOrderAmount && formik.errors.minimumOrderAmount ? String(formik.errors.minimumOrderAmount) : ''}
                  sx={{ width: '50%' }}
                />
              )}
            </Box>
          </Box>

          <Box mt={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <FormControlLabel
                control={
                  <Field
                    name="freeDeliveryAmountLimit"
                    type="checkbox"
                    as={StandardSwitch}
                    checked={formik.values.freeDeliveryAmountLimit !== undefined}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("freeDeliveryAmountLimit", event.target.checked ? 0 : undefined);
                    }}
                  />
                }
                label={`Free Delivery Limit (${currencyCode})`}
                sx={{ width: '50%' }}
              />
              {formik.values.freeDeliveryAmountLimit !== undefined && (
                <TextField
                  variant="outlined"
                  type="number"
                  {...formik.getFieldProps("freeDeliveryAmountLimit")}
                  inputProps={{ min: 0 }}
                  error={Boolean(formik.touched.freeDeliveryAmountLimit && formik.errors.freeDeliveryAmountLimit)}
                  helperText={formik.touched.freeDeliveryAmountLimit && formik.errors.freeDeliveryAmountLimit ? String(formik.errors.freeDeliveryAmountLimit) : ''}
                  sx={{ width: '50%' }}
                />
              )}
            </Box>
          </Box>

          <Box mt={2}>
            <Box display="flex" alignItems="center" gap={2}>
              <FormControlLabel
                control={
                  <Field
                    name="deliveryFee"
                    type="checkbox"
                    as={StandardSwitch}
                    checked={formik.values.deliveryFee !== undefined}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue("deliveryFee", event.target.checked ? 0 : undefined);
                    }}
                  />
                }
                label={`Delivery Fee (${currencyCode})`}
                sx={{ width: '50%' }}
              />
              {formik.values.deliveryFee !== undefined && (
                <TextField
                  variant="outlined"
                  type="number"
                  {...formik.getFieldProps("deliveryFee")}
                  inputProps={{ min: 0 }}
                  error={Boolean(formik.touched.deliveryFee && formik.errors.deliveryFee)}
                  helperText={formik.touched.deliveryFee && formik.errors.deliveryFee ? String(formik.errors.deliveryFee) : ''}
                  sx={{ width: '50%' }}
                />
              )}
            </Box>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
          <SecondaryButton text="Cancel" action={handleClose} />
          <StandardButton text="Save" action={handleSave} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, IconButton, TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { SecondaryButton, SecurityCodeClient, StandardButton, UserClient, UserUtil } from 'common.f6st.com';
import { toast } from 'react-toastify';
import { CallerType } from 'base.f6st.com';

interface ResetPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  email?: string;
}

export const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({ open, onClose, userId, email: initialEmail }) => {
  const [email, setEmail] = useState<string>(initialEmail || '');
  const [password, setPassword] = useState<string>('');
  const [passwordRetype, setPasswordRetype] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [codeId, setCodeId] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [verificationCodeError, setVerificationCodeError] = useState<string>('');

  useEffect(() => {
    if (open) {
      setEmail(initialEmail || '');
      setPassword('');
      setPasswordRetype('');
      setVerificationCode('');
      setCodeId(null);
      setEmailError('');
      setPasswordError('');
      setVerificationCodeError('');
    }
  }, [open, initialEmail]);

  const sendVerificationCode = async () => {
    setEmailError('');
    const subject = "F6ST verification code";
    const body = "Your reset password verification code is: {code}";
    const codeId = await SecurityCodeClient.createSecurityCodeMail(CallerType.ADMIN, email.trim(), subject, body, userId);
    if (codeId) {
      setCodeId(codeId);
      toast.success("A verification code has been sent to your email address.");
    } else {
      setEmailError("Your user ID or email does not exist. Please check and try again.");
    }
  };

  const handleResetPassword = async () => {
    setPasswordError('');
    setVerificationCodeError('');
    const trimmedPassword = password.trim();
    const trimmedConfirmPassword = passwordRetype.trim();
    const passwordCheckResult = UserUtil.checkPassword(trimmedPassword, trimmedConfirmPassword);

    if (passwordCheckResult === UserUtil.PASSWORD_COMPLEXITY_ERROR) {
      setPasswordError('Password does not meet complexity requirements.');
      return;
    }
    if (passwordCheckResult === UserUtil.PASSWORD_MISMATCH_ERROR) {
      setPasswordError('Passwords do not match.');
      return;
    }

    try {
      if (codeId) {
        const updated = await UserClient.updatePassword(CallerType.ADMIN, codeId, verificationCode, password);
        if (!updated) {
          setVerificationCodeError('Invalid verification code.');
          return;
        }
        toast.success("Your password has been reset successfully");
      }
      onClose();
    } catch (err) {
      setVerificationCodeError('An error occurred while resetting the password.');
    }
  };

  const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6" align="center" component="div">Reset Password</Typography>
        <IconButton onClick={onClose} style={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!codeId && (
          <TextField
            label="Your email address"
            variant="outlined"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange(setEmail)}
            required
            sx={{ mt: 2 }}
            error={!!emailError}
            helperText={emailError}
            InputProps={{
              readOnly: !!initialEmail,
            }}
          />
        )}
        {codeId && (
          <>
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={password}
              onChange={handleChange(setPassword)}
              required
              sx={{ mt: 2 }}
              error={!!passwordError}
              helperText={passwordError}
            />
            <TextField
              label="Retype New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={passwordRetype}
              onChange={handleChange(setPasswordRetype)}
              required
              sx={{ mt: 2 }}
              error={!!passwordError}
              helperText={passwordError}
            />
            <TextField
              label="Verification Code you received in email"
              variant="outlined"
              type="text"
              fullWidth
              value={verificationCode}
              onChange={handleChange(setVerificationCode)}
              required
              sx={{ mt: 2 }}
              error={!!verificationCodeError}
              helperText={verificationCodeError}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", gap: 2 }}>
          <SecondaryButton text="Cancel" action={onClose} />
          {codeId ? (
            <StandardButton text="Save" action={handleResetPassword} />
          ) : (
            <StandardButton text="Next" action={sendVerificationCode} />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

import { forwardRef } from 'react';
import { Switch, SwitchProps } from '@mui/material';

export const StandardSwitch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
    return (
        <Switch
            {...props}
            ref={ref}
            sx={{
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#e84b4bee',
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#e84b4bee',
                },
            }}
        />
    );
});

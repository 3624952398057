import { EnvUtil } from './EnvUtil';

type ConsoleType = {
  log: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  info: (message?: any, ...optionalParams: any[]) => void;
};

export class Log {
  private static console: ConsoleType = globalThis.console; // Default to the global console
  private static logLevel: string = 'DEBUG'; // Default log level

  private static levels: { [key: string]: number } = {
    'DEBUG': 0,
    'INFO': 1,
    'WARN': 2,
    'ERROR': 3,
  };

  public static async init(logLevel: string): Promise<void> {
    if (!this.console) {
      if (EnvUtil.isBrowser()) {
        // Running in a browser environment
        this.console = EnvUtil.getWindow().console;
      } else {
        // Assume Node.js (Lambda) environment
        const { Console } = await import('console');
        this.console = new Console(process.stdout, process.stderr);
      }
    }
    this.logLevel = logLevel.toUpperCase();
  }

  private static shouldLog(level: string): boolean {
    return this.levels[level] >= this.levels[this.logLevel];
  }

  private static logMessage(level: string, message: string, param?: any): void {
    if (!this.shouldLog(level)) return;

    if (param !== undefined) {
      this.console.log(`[${level}] ${message}`, param);
    } else {
      this.console.log(`[${level}] ${message}`);
    }
  }

  public static debug(message: string, param?: any): void {
    this.logMessage('DEBUG', message, param);
  }

  public static error(message: string, param?: any): void {
    this.logMessage('ERROR', message, param);
  }

  public static info(message: string, param?: any): void {
    this.logMessage('INFO', message, param);
  }

  public static warn(message: string, param?: any): void {
    this.logMessage('WARN', message, param);
  }
}

import { CallerType, Log } from 'base.f6st.com';

export class LoginUtil {

  private static readonly JWT_KEYS = {
    [CallerType.ORDER]: 'jwt_token_order',
    [CallerType.ADMIN]: 'jwt_token_admin',
    [CallerType.ROOT]: 'jwt_token_root',
  };

  private static readonly EXPIRATION_KEYS = {
    [CallerType.ORDER]: 'jwt_token_order_expiration',
    [CallerType.ADMIN]: 'jwt_token_admin_expiration',
    [CallerType.ROOT]: 'jwt_token_root_expiration',
  };

  private static readonly TOKEN_EXPIRATION_TIME_MS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds, this also handled in the LoginApi in the API package

  public static getTokenFromStorage(caller: CallerType): string | null {
    const tokenKey = this.JWT_KEYS[caller];
    const expirationKey = this.EXPIRATION_KEYS[caller];

    const expirationTime = localStorage.getItem(expirationKey);
    if (expirationTime && Date.now() > parseInt(expirationTime, 10)) {
      // Token is expired, remove it
      localStorage.removeItem(tokenKey);
      localStorage.removeItem(expirationKey);
      Log.debug(`Token for ${caller} expired and removed from local storage.`);
      return null;
    }
    return localStorage.getItem(tokenKey);
  }

  public static isAuthenticationToken(caller: CallerType): boolean {
    const token = this.getTokenFromStorage(caller);
    return token !== null;
  }

  public static removeAuthenticationToken(caller: CallerType): void {
    const tokenKey = this.JWT_KEYS[caller];
    const expirationKey = this.EXPIRATION_KEYS[caller];
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expirationKey);
    Log.debug(`Logged out and removed token and expiration for caller type: ${caller}`);
  }

  public static addTokenToStorage(caller: CallerType, token: string): void {
    const tokenKey = this.JWT_KEYS[caller];
    const expirationKey = this.EXPIRATION_KEYS[caller];

    const expirationTime = Date.now() + this.TOKEN_EXPIRATION_TIME_MS;

    localStorage.setItem(tokenKey, token);
    localStorage.setItem(expirationKey, expirationTime.toString());

    Log.debug(`Stored token and set expiration for caller type: ${caller}`);
  }
}

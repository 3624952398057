
import { customAlphabet } from 'nanoid';

const generateNanoId = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6);

export class IDUtil {
  public static getShortId(): string {
    return generateNanoId();
  }

}

import axios, { AxiosResponse } from 'axios';
import { AppError, CallerType, Log } from 'base.f6st.com';
import { LoginUtil } from './LoginUtil';

export class ApiUtil {

  public static isLocalApi(): boolean {
    return (process.env.NODE_ENV !== 'production');
  }

  public static async postRequest<T>(caller: CallerType, method: string, data: any): Promise<T> {
    try {
      const url = `https://api.f6st.com/${caller}/${method}`;
      
      // Retrieve the JWT token for the caller type
      const token = LoginUtil.getTokenFromStorage(caller);
  
      const headers: any = {
        'Content-Type': 'application/json'
      };
  
      // If a token exists, add it to the request Authorizationheader
      if (token) {
        headers['Authorization'] = `${token}`;
      }
  
      const response: AxiosResponse = await axios.post(url, data, { headers });
      const result: T = this.parseResponse<T>(response);
      
      return result;
    } catch (error) {
      throw new AppError("Calling axios remotely",error);
    }
  }

  public static parseResponse<T>(response: AxiosResponse): T {
    if (response.status !== 200) {
      Log.error(`Axios response returned status ${response.status}`, { data: response.data });
      throw new Error(`Request failed with status code ${response.status}`);
    }

    return response.data as T;
  }


}

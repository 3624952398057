import { AppError } from "./AppError";
import { EnvUtil } from "./EnvUtil";

export class FileEncoderUtil {
    
    // Encodes a File to a Base64 string in a browser environment
    public static async clientEncode(file: File): Promise<string> {
        if (!EnvUtil.isBrowser()) {
            throw new AppError("clientEncode can only be run in a browser environment");
        }
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer
            reader.onload = () => {
                const buffer = reader.result as ArrayBuffer;
                const binaryString = Array.from(new Uint8Array(buffer))
                    .map(byte => String.fromCharCode(byte))
                    .join('');
                const base64String = btoa(binaryString);
                resolve(base64String);
            };
            reader.onerror = () => {
                reject(new AppError("Failed to encode file to Base64"));
            };
        });
    }

    // Decodes a Base64 string to a Uint8Array (binary data) in both browser and Node.js environments
    public static serverDecode(fileBase64: string): Uint8Array {
        if (EnvUtil.isBrowser()) {
            const binaryString = atob(fileBase64); // Decode Base64 to binary string
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        } else if (typeof Buffer !== 'undefined') {
            return Buffer.from(fileBase64, 'base64'); // Convert Base64 string to Buffer
        } else {
            throw new AppError("serverDecode can only be run in browser or Node.js environment");
        }
    }

    public static calculateByteSize(fileBase64: string): number {
        return this.serverDecode(fileBase64).length; 
    }
}

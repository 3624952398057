import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, FormControl, InputLabel, MenuItem, Select, FormHelperText, Alert } from '@mui/material';
import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { GeoLocationUtil, StandardButton } from 'common.f6st.com';
import i18next from 'i18next';
import { RegistrationPageInputs } from '../pages/RegistrationPage';
import { I18nUtil } from 'base.f6st.com';

interface RegistrationPageFormProps {
  message: string;
  errors: FormikErrors<RegistrationPageInputs>;
  touched: FormikTouched<RegistrationPageInputs>;
}

export const RegistrationPageForm: React.FC<RegistrationPageFormProps> = ({ message, errors, touched }) => {
  const [countryAlert, setCountryAlert] = useState<string | null>(null);
  const [isCountryOpen, setIsCountryOpen] = useState<boolean>(false);
  const supportedCountries = I18nUtil.getSupportedCountries();
  const formik = useFormikContext<RegistrationPageInputs>();

  useEffect(() => {
    const initializeCountry = async () => {
      const handleCountrySelection = (countryCode: string) => {
        const country = I18nUtil.getSupportedCountry(countryCode);
        if (country.openForRegistration) {
          setCountryAlert(null);
          setIsCountryOpen(true);
        } else {
          setCountryAlert(`For registration, please contact us at info@f6st.com!`);
          setIsCountryOpen(false);
        }
      };

      if (formik.values.countryCode) {
        handleCountrySelection(formik.values.countryCode);
      } else {
        const { countryCode}  = await GeoLocationUtil.detectLocation();
        const supportedDetectedCountry = supportedCountries.find(country => country.countryCode === countryCode);
        if (supportedDetectedCountry) {
          formik.setFieldValue('countryCode', supportedDetectedCountry.countryCode);
          handleCountrySelection(supportedDetectedCountry.countryCode);
        } else {
          setCountryAlert(`Currently we don't support the country of your location`);
        }
      }
    };
    initializeCountry();
  }, [formik.values.countryCode, supportedCountries, formik]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      <FormControl fullWidth required error={Boolean(errors.countryCode && touched.countryCode)}>
        <InputLabel>Country of your business</InputLabel>
        <Select
          name="countryCode"
          value={formik.values.countryCode}
          onChange={(e) => {
            formik.handleChange(e);
            const selectedCountry = supportedCountries.find(country => country.countryCode === e.target.value);
            if (selectedCountry && !selectedCountry.openForRegistration) {
              setCountryAlert(`For registration, please contact us at info@f6st.com!`);
              setIsCountryOpen(false);
            } else {
              setCountryAlert(null);
              setIsCountryOpen(true);
            }
          }}
          label="Country of your business"
        >
          {supportedCountries.map((country) => (
            <MenuItem value={country.countryCode} key={country.countryCode}>
              {i18next.t(`country.${country.countryCode}`)}
            </MenuItem>
          ))}
        </Select>
        {errors.countryCode && touched.countryCode && <FormHelperText error>{errors.countryCode}</FormHelperText>}
      </FormControl>
      {countryAlert && <Alert severity="error">{countryAlert}</Alert>}
      {isCountryOpen && (
        <>
          <TextField
            label="Your Name"
            variant="outlined"
            {...formik.getFieldProps('name')}
            error={Boolean(errors.name && touched.name)}
            helperText={errors.name && touched.name ? errors.name : ''}
            required
          />
          <TextField
            label="Email"
            variant="outlined"
            {...formik.getFieldProps('email')}
            error={Boolean(errors.email && touched.email)}
            helperText={errors.email && touched.email ? errors.email : ''}
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            {...formik.getFieldProps('password')}
            error={Boolean(errors.password && touched.password)}
            helperText={errors.password && touched.password ? errors.password : ''}
            required
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            {...formik.getFieldProps('confirmPassword')}
            error={Boolean(errors.confirmPassword && touched.confirmPassword)}
            helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : ''}
            required
          />
          {message && <Typography color="primary">{message}</Typography>}
          <StandardButton text="Next" action={formik.handleSubmit} />
        </>
      )}
    </Box>
  );
};


import { CustomerClient } from "common.f6st.com";
import { useAppContext } from "../components/AppProvider";
import { Container, Paper } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { BusinessSettingsForm } from "../components/BusinessSettingsForm";
import { CustomAppBar } from "../components/CustomAppBar";
import { BusinessSettings, CallerType, CustomerUpdateAttributes } from "base.f6st.com";

export const BusinessSettingsPage = () => {
    const customer = useAppContext().getCustomer();
    const navigate = useNavigate();

    const settingsCopy = { ...customer.businessSettings };

    const onSubmit: (data: BusinessSettings, verificationCode?: string) => void = async (data, verificationCode) => {

        const updates = [
            {
                attribute: CustomerUpdateAttributes.BUSINESSSETTINGS,
                value: data
            }
        ];

        CustomerClient.updateCustomer(CallerType.ADMIN, customer.id, updates);
        customer.businessSettings = { ...data };
        toast.success("Business Settings saved successfully.");
        navigate('/');
    };

    return (
        <>
            <CustomAppBar backButton pageTitle="Business Settings" />
            <Container sx={{ maxWidth: '800px', marginX: 'auto' }}>
                <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                    <BusinessSettingsForm
                        onSubmitCallback={onSubmit}
                        useCase="FULLSETTINGS"
                        existingSettings={settingsCopy}
                    />
                </Paper>
            </Container>
        </>
    );
};
import React, { useState, useEffect } from 'react';
import { Box, Link, Checkbox, FormControlLabel, TextField, FormGroup, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ReceiptPdfUtil } from '../util/ReceiptPdfUtil';
import { BusinessDetails, Customer, Order } from 'base.f6st.com';

interface ReceiptLinkProps {
  order: Order;
  customer: Customer;
  businessReceipt: boolean; // show option to enter business receipt data
}

const businessReceiptSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  companyAddress: Yup.string().required('Required'),
  vatID: Yup.string(), // VAT ID is optional
});

export const ReceiptLink: React.FC<ReceiptLinkProps> = ({ order, customer, businessReceipt }) => {
  const { t } = useTranslation();
  const [isBusinessReceipt, setIsBusinessReceipt] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('businessDetails');
    if (storedData) {
      order.businessDetails = JSON.parse(storedData);
    }
  }, [order]);

  const handleDownloadReceipt = async () => {
    try {
      const translatedLabels = {
        receiptNumber: t('Receipt Number'),
        date: t('Date'),
        quantity: t('QTY'),
        item: t('Item'),
        price: t('Price'),
        vat: t('VAT'),
        netTotal: t('Net Total'),
        total: t('Total'),
        tip: t('Tip'),
        serviceFee: t('Service Fee'),
        discount: t('Discount'),
        vatID: t('VAT ID'),
        phone: t('Phone'),
        deliveryFee: t('Delivery Fee'),
        laterPaymentInPerson: t('In person'),
        paymentMethod: t('Payment Method'),
        deliveryInfo: t('Delivery Information'),
        fullName: t('Full Name'),
        deliveryInstructions: t('Delivery Instructions'),
        address: t('Address'),
        businessDetails: t('Business Details'),
        companyName: t('Name'),
        companyAddress: t('Address'),
      };

      const pdfBytes = await ReceiptPdfUtil.generateReceiptPDF(order, customer, translatedLabels);
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, `Receipt_${order.id}.pdf`);
    } catch (error) {
      console.error('Error generating receipt PDF:', error);
    }
  };

  const handleBusinessDetailsChange = (field: keyof BusinessDetails, value: string) => {
    if (!order.businessDetails) {
      order.businessDetails = {
        companyName: '',
        companyAddress: '',
        vatID: '',
      };
    }
    order.businessDetails = {
      ...order.businessDetails,
      [field]: value,
    };
    // Save to localStorage whenever the value changes
    localStorage.setItem('businessDetails', JSON.stringify(order.businessDetails));
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Link href="#" onClick={() => handleDownloadReceipt()} style={{ fontSize: '0.875rem' }}>
          {t('Download')}
        </Link>

        {businessReceipt && ( // Conditionally render the checkbox based on the param
          <FormControlLabel
            control={
              <Checkbox
                checked={isBusinessReceipt}
                onChange={(e) => setIsBusinessReceipt(e.target.checked)}
                size="small"
              />
            }
            label={<Typography style={{ fontSize: '0.875rem' }}>{t('Business Receipt')}</Typography>}
          />
        )}
      </Box>

      {isBusinessReceipt && (
        <Formik<BusinessDetails>
          initialValues={
            order.businessDetails || {
              companyName: '',
              companyAddress: '',
              vatID: '',
            }
          }
          validationSchema={businessReceiptSchema}
          onSubmit={(values, actions: FormikHelpers<BusinessDetails>) => {
            handleDownloadReceipt();
            actions.setSubmitting(false);
          }}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form style={{ width: '100%' }}>
              <FormGroup>
                <TextField
                  name="companyName"
                  label={t('Company Name')}
                  value={values.companyName}
                  onChange={(e) => {
                    handleChange(e);
                    handleBusinessDetailsChange('companyName', e.target.value);
                  }}
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={
                    touched.companyName && errors.companyName ? errors.companyName : undefined
                  }
                  fullWidth
                  style={{ marginTop: '16px', marginBottom: '16px' }}
                />
                <TextField
                  name="companyAddress"
                  label={t('Company Address')}
                  value={values.companyAddress}
                  onChange={(e) => {
                    handleChange(e);
                    handleBusinessDetailsChange('companyAddress', e.target.value);
                  }}
                  error={touched.companyAddress && Boolean(errors.companyAddress)}
                  helperText={
                    touched.companyAddress && errors.companyAddress ? errors.companyAddress : undefined
                  }
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
                <TextField
                  name="vatID"
                  label={t('VAT ID')}
                  value={values.vatID || ''}
                  onChange={(e) => {
                    handleChange(e);
                    handleBusinessDetailsChange('vatID', e.target.value);
                  }}
                  error={touched.vatID && Boolean(errors.vatID)}
                  helperText={
                    touched.vatID && errors.vatID ? errors.vatID : undefined
                  }
                  fullWidth
                  style={{ marginBottom: '16px' }}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

import { EnvUtil, FileEncoderUtil, Log } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class S3Client {

  public static async uploadImage(caller: any, file: File, folderName: string): Promise<string> {
    // Encode the file to a Base64 string using FileEncoderUtil
    const fileBase64 = await FileEncoderUtil.clientEncode(file);

    if (ApiUtil.isLocalApi()) {
      const S3Api = await this.getS3Api();
      // Pass the Base64 string 
      return await S3Api.uploadImage(fileBase64, folderName);
    } else {
      const response = await ApiUtil.postRequest<string>(
        caller,
        's3/uploadImage',
        { fileBase64: fileBase64, folderName }
      );
      Log.debug("Image has been uploaded with the following file id", response);
      return response;
    }
  }

  public static async cleanup(caller: any, folderName: string, imageIdsToKeep: string[]): Promise<void> {
    Log.debug('Cleaning up folder via client', folderName);

    if (ApiUtil.isLocalApi()) {
      const S3Api = await this.getS3Api();
      await S3Api.cleanup(folderName, imageIdsToKeep);
    } else {
      await ApiUtil.postRequest<void>(caller, 's3/cleanup', { folderName, imageIdsToKeep });
    }
  }

  private static async getS3Api() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).S3Api;
  }
}

export class UserUtil {
    
    static readonly PASSWORD_COMPLEXITY_ERROR = 'PASSWORD_COMPLEXITY_ERROR';
    static readonly PASSWORD_MISMATCH_ERROR = 'PASSWORD_MISMATCH_ERROR';

    static checkPassword(password: string, passwordRetyped: string): string | null {
        // Trim both passwords
        const trimmedPassword = password.trim();
        const trimmedPasswordRetyped = passwordRetyped.trim();

        // Updated complexity check: at least 6 characters
        if (trimmedPassword.length < 6) {
            return UserUtil.PASSWORD_COMPLEXITY_ERROR;
        }
        // Check if the passwords match
        if (trimmedPassword !== trimmedPasswordRetyped) {
            return UserUtil.PASSWORD_MISMATCH_ERROR;
        }
        return null;
    }

}

import { Address, Log, MetricSystem } from "base.f6st.com";
import { getDistance } from 'geolib';
import { Config } from "./Config";

const CACHE_KEY_COUNTRY = "cachedCountryCode";
const CACHE_KEY_ZIP = "cachedZipCode";

export class GeoLocationUtil {
    
    static async detectLocation(): Promise<{ countryCode: string | null, zipCode: string | null }> {
        Log.debug("Starting location detection.");

        const cachedCountryCode = localStorage.getItem(CACHE_KEY_COUNTRY);
        const cachedZipCode = localStorage.getItem(CACHE_KEY_ZIP);

        if (cachedCountryCode && cachedZipCode) {
            Log.debug(`Using cached location data: countryCode=${cachedCountryCode}, zipCode=${cachedZipCode}`);
            return { countryCode: cachedCountryCode, zipCode: cachedZipCode };
        }

        Log.debug("No cached data found, calling IP info service.");
        const data = await this.callInfo();
        if (!data) {
            Log.debug("No data received from IP info service.");
            return { countryCode: null, zipCode: null };
        }

        const countryCode = data.country || null;
        const zipCode = data.postal || null;

        if (countryCode) {
            Log.debug(`Caching country code: ${countryCode}`);
            localStorage.setItem(CACHE_KEY_COUNTRY, countryCode);
        }
        if (zipCode) {
            Log.debug(`Caching zip code: ${zipCode}`);
            localStorage.setItem(CACHE_KEY_ZIP, zipCode);
        }

        return { countryCode, zipCode };
    }

    private static async callInfo(): Promise<any | null> {
        const apiKey = Config.IP_INFO_KEY_FOR_COUNTRY_DETECTION;
        Log.debug(`Calling IP info service with API key: ${apiKey}`);
        try {
            const response = await fetch(`https://ipinfo.io/json?token=${apiKey}`);
            if (response.status === 200) {
                const data = await response.json();
                Log.debug("Received data from IP info service:", data);
                return data;
            } else {
                Log.error(`Received status code ${response.status} from IPinfo API.`);
            }
        } catch (error) {
            Log.error("Error fetching IP info", error);
        }
        return null;
    }

    public static async getLatLng(): Promise<google.maps.LatLngLiteral | undefined> {
        Log.debug("Attempting to get current position using geolocation.");
        if (navigator.geolocation) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        Log.debug(`Geolocation success: lat=${position.coords.latitude}, lng=${position.coords.longitude}`);
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    async () => {
                        Log.debug("Geolocation failed, falling back to IP info service.");
                        const fallbackData = await this.callInfo();
                        if (fallbackData && fallbackData.loc) {
                            const [lat, lng] = fallbackData.loc.split(',');
                            Log.debug(`Fallback success: lat=${lat}, lng=${lng}`);
                            resolve({ lat: parseFloat(lat), lng: parseFloat(lng) });
                        } else {
                            Log.debug("Fallback to IP info service failed.");
                            resolve(undefined);
                        }
                    }
                );
            });
        } else {
            Log.debug("Geolocation not supported, falling back to IP info service.");
            const fallbackData = await this.callInfo();
            if (fallbackData && fallbackData.loc) {
                const [lat, lng] = fallbackData.loc.split(',');
                Log.debug(`Fallback success: lat=${lat}, lng=${lng}`);
                return { lat: parseFloat(lat), lng: parseFloat(lng) };
            }
        }
        Log.debug("Geolocation and fallback to IP info service failed.");
        return undefined;
    }

    public static isWithinDistance(
        businessAddress: Address,
        customerAddress: Address,
        distance: number,
        metricSystem: MetricSystem
    ): boolean {
        const distanceInMeters = getDistance(
            { latitude: businessAddress.lat, longitude: businessAddress.lng },
            { latitude: customerAddress.lat, longitude: customerAddress.lng }
        );

        const distanceInDesiredUnit = metricSystem === MetricSystem.KM
            ? distanceInMeters / 1000
            : distanceInMeters / 1609.34; // 1 mile = 1609.34 meters

        Log.debug(`Calculated distance: ${distanceInDesiredUnit} ${metricSystem}`);
        return distanceInDesiredUnit <= distance;
    }
}

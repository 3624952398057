import Box from '@mui/material/Box';
import Slider, { SliderProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

interface StandardSliderProps extends SliderProps {
  unit: string;
}

function valuetext(value: number, unit: string) {
  return `${value}${unit}`;
}

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#e84b4bee',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 38,
    height: 38,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#e84b4bee',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

export function StandardSlider({ unit, ...props }: StandardSliderProps) {
  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <CustomSlider
        aria-label="Temperature"
        defaultValue={20}
        getAriaValueText={(value: number) => valuetext(value, unit)}  
        valueLabelDisplay="on"
        min={0}
        max={100}
        valueLabelFormat={(value: number) => valuetext(value, unit)} 
        {...props}
      />
    </Box>
  );
}
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    TextField,
    Autocomplete,
    Box,
    Stack,
    Typography,
    FormLabel,
    Checkbox,
    FormControlLabel,
    Paper,
    Container
} from "@mui/material";
import { toast } from 'react-toastify';
import { ImageResizerDialog } from "../components/dialogs/ImageResizerDialog";
import {
    SizesDialog,
    AddExtraDialog
} from "../components/dialogs/ProductDetailDialogs";
import {
    Product,
    Customer,
    ProductsTextsUtil,
    IDUtil,
    ProductExtra,
    Size,
    ProductFilter,
    CallerType,
    I18nUtil,
    Log
} from "base.f6st.com";
import {
    S3Client,
    Config,
    SecondaryButton,
    StandardButton
} from "common.f6st.com";
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomAppBar } from "../components/CustomAppBar";
import { DeleteConfirmDialog } from "../components/dialogs/DeleteConfirmDialog";  // Import DeleteConfirmDialog

// -----------------------------------------------------------------------------
/**
 * Validation schema remains the same.
 */
const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string()
        .required('Description is required')
        .max(300, 'Description cannot exceed 300 characters'),
    singlePrice: yup
        .number()
        .typeError('Price must be a number')
        .nullable()
        .when('multipleSizes', {
            is: false,
            then: (schema) => schema
                .required('Price is required')
                .positive('Price must be positive'),
            otherwise: (schema) => schema.nullable(),
        }),
    multipleSizes: yup.boolean(),
    sizes: yup.array().of(
        yup.object({
            id: yup.string().required('Size ID is required'),
            price: yup
                .number()
                .typeError('Price must be a number')
                .required('Price is required')
                .positive('Size price must be positive'),
        })
    ).when('multipleSizes', {
        is: true,
        then: (schema) => schema
            .min(2, 'At least two sizes are required for products with multiple sizes'),
        otherwise: (schema) => schema.notRequired(),
    }),
    filters: yup.array().of(yup.string().required()).notRequired(),
    extras: yup.array().of(
        yup.object({
            id: yup.string().required('Extra ID is required'),
            price: yup
                .number()
                .typeError('Price must be a number')
                .required('Price is required')
                .positive('Price must be positive'),
        })
    ).notRequired(),
});

// -----------------------------------------------------------------------------
/**
 * The main ProductDetailPage component.
 */
export const ProductDetailPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const product: Product = location.state?.product;
    const customer: Customer = location.state?.customer;
    const txt = new ProductsTextsUtil(customer);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const currencyCode = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);

    // Dialog states
    const [managePricesAndSizesDialogOpen, setManagePricesAndSizesDialogOpen] = useState(false);
    const [manageExtrasDialogOpen, setManageExtrasDialogOpen] = useState(false);
    const [multipleSizes, setMultipleSizes] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    // Image handling states
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Initialize multipleSizes based on the product
    useEffect(() => {
        if (product && product.sizes) {
            setMultipleSizes(product.sizes.length > 0);
        } else {
            setMultipleSizes(false);
        }
    }, [product]);

    // Check if the page is for adding a new product or updating
    useEffect(() => {
        setIsAdd(location.state?.categoryId !== undefined);
    }, []);

    // -------------------------------------------------------------------------
    // Formik
    // -------------------------------------------------------------------------
    const formik = useFormik({
        enableReinitialize: true, 
        initialValues: product ? {
            name: txt.get(product.id,""),
            description: txt.get(product.descriptionId,""),
            imgId: product.imgId || "",
            singlePrice: product.price?.toString() || "",
            multipleSizes: multipleSizes,
            sizes: product.sizes || [],
            filters: product.filterIds || [],
            extras: product.extras || [],
        } : {
            name: "",
            description: "",
            imgId: "",
            singlePrice: "",
            multipleSizes: false,
            sizes: [],
            filters: [],
            extras: [],
        },
        validationSchema,
        onSubmit: (values) => {
            if (!product) {
                toast.error("No product selected.");
                return;
            }

            const descriptionId = product.descriptionId || IDUtil.getShortId();

            // Update texts
            txt.set(product.id, values.name);
            txt.set(descriptionId, values.description);

            const updatedProduct: Product = {
                ...product,
                descriptionId,
                imgId: values.imgId,
                price: values.multipleSizes
                    ? undefined
                    : parseFloat(values.singlePrice || "0"),
                sizes: values.multipleSizes ? values.sizes : undefined,
                filterIds: values.filters,
                extras: values.extras,
            };

            if (isAdd) { // add
                const categoryId = location.state?.categoryId;
                const updatedCategory = customer.products.categories.find(
                    category => category.id === categoryId
                );
                if (updatedCategory) {
                    updatedCategory.products.push(updatedProduct);
                    customer.products.categories = [...customer.products.categories];
                }

                toast.success("Product added successfully.");

            } else { // update

                // Update the customer object with the updated product
                customer.products.categories = customer.products.categories.map((category) => {
                    category.products = category.products.map((prod) => prod.id === updatedProduct.id ? updatedProduct : prod);
                    return category;
                });

                toast.success("Product updated successfully.");

            }
            
            const selectedCategory = location.state?.selectedCategory;

            navigate('/products', { state: { customer, selectedCategory } });
        },
        validateOnChange: true,
        validateOnBlur: true,
    });

    // -------------------------------------------------------------------------
    // Image handling
    // -------------------------------------------------------------------------
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = async () => {
                if (img.width < 600 || img.height < 600) {
                    toast.error("Image is too small. Minimum dimensions are 600x600 pixels.");
                    return;
                }

                // If it's exactly 600x600, upload directly
                if (img.width === 600 && img.height === 600) {
                    const imgId = await S3Client.uploadImage(CallerType.ADMIN, file, customer.id);
                    handleImageSave(imgId);
                } else {
                    // Otherwise, open crop modal
                    const objectUrl = URL.createObjectURL(file);
                    setImageSrc(objectUrl);
                    setIsCropModalOpen(true);
                }
            };
        }
    };

    const handleImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageSave = useCallback(async (imgId: string) => {
        // Update the formik & local product state
        formik.setFieldValue('imgId', imgId);

        // Set the image source to the new image URL
        const newImageSrc = Config.getImageUrl(customer.id, imgId); // Get the image URL for the updated imgId
        setImageSrc(newImageSrc); // Update the imageSrc state to force a re-render

        setIsCropModalOpen(false); // Close the crop modal
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset file input
        }
        toast.success("Image has been successfully changed");
    }, [formik, customer]);

    // -------------------------------------------------------------------------
    // Multiple sizes toggle
    // -------------------------------------------------------------------------
    const handleMultipleSizesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setMultipleSizes(checked);
        formik.setFieldValue('multipleSizes', checked);

        if (!checked) {
            // If turning OFF multiple sizes, clear sizes
            formik.setFieldValue('sizes', []);
        }
    };

    // -------------------------------------------------------------------------
    // Open sub-dialogs for sizes & extras
    // -------------------------------------------------------------------------
    const handleManageSizes = () => {
        setManagePricesAndSizesDialogOpen(true);
    };

    const handleManageExtras = () => {
        setManageExtrasDialogOpen(true);
    };

    // For the sub-dialogs, we get an updated product from them
    // We'll propagate that back to formik
    const handleSizesDialogSave = (updatedProduct: Product) => {
        setManagePricesAndSizesDialogOpen(false);
        formik.setFieldValue('sizes', updatedProduct.sizes || []);
    };

    const handleExtrasDialogSave = (updatedProduct: Product) => {
        setManageExtrasDialogOpen(false);
        formik.setFieldValue('extras', updatedProduct.extras || []);
    };

    // -------------------------------------------------------------------------
    // Button actions
    // -------------------------------------------------------------------------
    const handleCancelClick = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const handleDeleteClick = () => {
        setConfirmDeleteDialogOpen(true); // Open the delete confirmation dialog
    };

    const handleConfirmDelete = () => {
        if (!product) return;

        // Remove product from its category (in memory)   
        customer.products.categories.forEach((category) => {
            category.products = category.products.filter(prod => prod.id !== product.id);
        });

        toast.success("Product deleted successfully.");
        navigate('/products', { state: { customer } });
    };

    const handleCancelDelete = () => {
        setConfirmDeleteDialogOpen(false); // Close the delete confirmation dialog
    };

    const handleActionClick = async () => {
        const errors = await formik.validateForm();

        if (Object.keys(errors).length > 0) {
            formik.setTouched(
                Object.keys(formik.values).reduce((acc: Record<string, boolean>, key: string) => {
                    acc[key] = true;
                    return acc;
                }, {})
            );
            toast.error("Please fix validation errors before proceeding.");
        } else {
            formik.handleSubmit(); // Calls onSubmit => onUpdate
        }
    };

    // -------------------------------------------------------------------------
    // Render the component
    // -------------------------------------------------------------------------
    if (!product) {
        return <Typography>Loading...</Typography>;
    }
    
    return (
        <>
            <CustomAppBar backButton pageTitle="Product Detail" />
            <Container>
                <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                    <Box ref={componentRef} sx={{ p: 3 }}>
                        <form onSubmit={formik.handleSubmit}>
                            <FormLabel component="legend" sx={{ mt: 2 }}>
                                Product Image (600x600 pixels)
                            </FormLabel>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    mt: 1,
                                    cursor: 'pointer',
                                    position: 'relative',
                                    width: '300px',
                                    height: '300px'
                                }}
                                onClick={handleImageClick}
                            >
                                <img
                                    src={imageSrc || (product.imgId ? Config.getImageUrl(customer.id, product.imgId) : "assets/noimage.webp")}
                                    alt="Product"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: 'contain'
                                    }}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        position: 'absolute',
                                        textAlign: 'center',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        fontWeight: 'bold',
                                        color: 'white',
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        padding: '5px'
                                    }}
                                >
                                    Click here to upload
                                </Typography>
                            </Box>
                            <input
                                accept=".jpg,.jpeg,.png,.webp"
                                style={{ display: 'none' }}
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                            />

                            <TextField
                                label="Name"
                                fullWidth
                                margin="normal"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />

                            <TextField
                                label="Description"
                                fullWidth
                                margin="normal"
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />

                            <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={multipleSizes}
                                            onChange={handleMultipleSizesChange}
                                            color="primary"
                                            id="multipleSizes"
                                            name="multipleSizes"
                                        />
                                    }
                                    label="Multiple sizes"
                                />

                                {multipleSizes ? (
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        sx={{ flex: 1 }}
                                    >
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            disableClearable
                                            options={formik.values.sizes ?? []}
                                            getOptionLabel={(option: Size) =>
                                                `${txt.get(option.id) || 'Unnamed size'} (${option.price} ${currencyCode})`
                                            }
                                            value={formik.values.sizes ?? []}
                                            onChange={(e, newValue) => {
                                                formik.setFieldValue('sizes', newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Sizes"
                                                    fullWidth
                                                    error={Boolean(formik.touched.sizes && formik.errors.sizes)}
                                                />
                                            )}
                                        />
                                        <SecondaryButton
                                            text="Add"
                                            action={handleManageSizes}
                                            style={{ width: '100px' }}
                                        />
                                    </Stack>
                                ) : (
                                    <TextField
                                        label={`Price (${currencyCode})`}
                                        fullWidth
                                        margin="normal"
                                        id="singlePrice"
                                        name="singlePrice"
                                        value={formik.values.singlePrice}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.singlePrice && Boolean(formik.errors.singlePrice)}
                                        helperText={formik.touched.singlePrice && formik.errors.singlePrice}
                                        type="number"
                                        inputProps={{ min: 0, step: "any" }}
                                    />
                                )}
                            </Stack>

                            {formik.touched.sizes && formik.errors.sizes && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    {typeof formik.errors.sizes === 'string' ? formik.errors.sizes : ''}
                                </Typography>
                            )}

                            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                <Autocomplete
                                    multiple
                                    disableClearable
                                    fullWidth
                                    options={formik.values.extras || []}
                                    getOptionLabel={(extra: ProductExtra) =>
                                        `${txt.get(extra.id) || 'Unnamed extra'} (${extra.price} ${currencyCode})`
                                    }
                                    value={formik.values.extras || []}
                                    onChange={(e, newValue: ProductExtra[]) => {
                                        formik.setFieldValue('extras', newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Extras"
                                            fullWidth
                                            error={Boolean(formik.touched.extras && formik.errors.extras)}
                                        />
                                    )}
                                />
                                <SecondaryButton
                                    text="Add"
                                    action={handleManageExtras}
                                    style={{ width: '100px' }}
                                />
                            </Stack>

                            {formik.touched.extras && formik.errors.extras && (
                                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                    {typeof formik.errors.extras === 'string' ? formik.errors.extras : ''}
                                </Typography>
                            )}

                            <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                <Autocomplete
                                    multiple
                                    disableClearable
                                    fullWidth
                                    options={customer.products.filters || []}
                                    getOptionLabel={(option: ProductFilter) =>
                                        txt.get(option.id) || 'Unnamed Filter'
                                    }
                                    // Map product.filterIds to real filter objects
                                    value={(formik.values.filters || [])
                                        .map(filterId => (customer.products.filters || [])
                                            .find(f => f.id === filterId))
                                        .filter(Boolean) as ProductFilter[]}
                                    onChange={(e, newValue: ProductFilter[]) => {
                                        const newIds = newValue.map(f => f.id);
                                        formik.setFieldValue('filters', newIds);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Filters"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Stack>

                            <SizesDialog
                                open={managePricesAndSizesDialogOpen}
                                onClose={() => setManagePricesAndSizesDialogOpen(false)}
                                product={{
                                    ...product,
                                    sizes: formik.values.sizes || [],
                                }}
                                txt={txt}
                                onSave={handleSizesDialogSave}
                            />
                            <AddExtraDialog
                                open={manageExtrasDialogOpen}
                                onClose={() => setManageExtrasDialogOpen(false)}
                                product={{
                                    ...product,
                                    extras: formik.values.extras || [],
                                }}
                                txt={txt}
                                onSave={handleExtrasDialogSave}
                            />
                            <ImageResizerDialog
                                open={isCropModalOpen}
                                onClose={() => setIsCropModalOpen(false)}
                                imageSrc={imageSrc}
                                onSave={handleImageSave}
                                customerId={customer.id}
                                imgId={formik.values.imgId}
                            />
                        </form>
                    </Box>

                    <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
                        <SecondaryButton text="Cancel" action={handleCancelClick} />
                        <SecondaryButton text="Delete" action={handleDeleteClick} />
                        <StandardButton text={isAdd ? "Add" : "Update"} action={handleActionClick} />
                    </Stack>
                </Paper>
            </Container>

            {/* Delete Confirmation Dialog */}
            <DeleteConfirmDialog
                open={confirmDeleteDialogOpen}
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>
    );
};

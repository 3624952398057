import { ProductsTexts, Customer, Products } from "../types/CustomerTypes.js";
import { Order } from "../types/OrderTypes.js";
import { AppError } from "./AppError.js";
import { IDUtil } from "./IDUtil.js";
import { Log } from "./Log.js";
import { ProductUtil } from "./ProductUtil.js";

export class ProductsTextsUtil {
  private productsTexts: ProductsTexts;
  private languageCode: string;

  public createOrderTexts(customer: Customer, order: Order): { [key: string]: string } {
    const texts: { [key: string]: string } = {};

    // Loop through each item in the order
    order.items.forEach(item => {
      // Get the product name text
      texts[item.product.id] = this.get(item.product.id);

      // Get the category name text based on the categoryId
      const category = customer.products.categories.find(cat => cat.id === item.categoryId);
      if (category) {
        texts[item.categoryId] = this.get(category.id);
      }

      // Get the size name text if a sizeId is present
      if (item.sizeId) {
        const size = item.product.sizes?.find(size => size.id === item.sizeId);
        if (size) {
          texts[size.id] = this.get(size.id);
        }
      }

      // Get the extras' names if extraIds are present
      if (item.extraIds) {
        item.extraIds.forEach(extraId => {
          const extra = item.product.extras.find(extra => extra.id === extraId);
          if (extra) {
            texts[extra.id] = this.get(extra.id);
          }
        });
      }
    });

    return texts;
  }


  constructor(customer: Customer, languageCode?: string) {
    this.languageCode = languageCode || customer.businessSettings.languageCodePrimary;

    // Check if the customer has product texts for the specified language
    this.productsTexts = ProductUtil.getProductsTexts(customer, this.languageCode);

    // If no productsTexts for the language, create a new one with a unique ID
    if (!this.productsTexts) {
      Log.debug("No productsTexts found for language, creating new emnpty product text", { languageCode: this.languageCode });
      this.productsTexts = {
        version: IDUtil.getShortId()
      };
    }
  }

  public get(textId: string | undefined, emptyValue?: string): string {
    if (!this.exists(textId)) {
      Log.warn("No text id or text not found", { textId, languageCode: this.languageCode, productsTexts: this.productsTexts });
      if (emptyValue !== undefined) return emptyValue;
      return '<Unknown>';
    }
    if (!textId) throw new AppError("No text id");
    const res = this.productsTexts[textId].trim();
    return res;
  }
  
  public exists(textId: string | undefined): boolean {
    return !!textId && this.productsTexts.hasOwnProperty(textId);
  }

  public set(textId: string, text: string): void {
    if (!textId) {
      Log.warn("No text id provided", { textId, languageCode: this.languageCode, text });
      return;
    }
    this.productsTexts[textId] = text.trim();
  }

  public get allProductsTexts(): ProductsTexts {
    return this.productsTexts;
  }

  public getVersion(): string {
    return this.productsTexts.version;
  }

}

import { CallerType, Customer, EnvUtil, Log, QrCode, User } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';
import { LoginUtil } from '../util/LoginUtil';

export class LoginClient {

  public static async isFirstAdminLogin( userId: string): Promise<boolean | undefined> {
    Log.debug('Loading user data by ID from DynamoDB', { userId });
    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      return await LoginApi.isFirstAdminLogin(userId);
    } else {
      return await ApiUtil.postRequest<boolean | undefined>(CallerType.ADMIN, "login/isFirstAdminLogin", { userId });
    }
  }

  public static async loginOrder(qrCode: QrCode, languageCode: string): Promise<Customer | undefined> {
    Log.debug('Loading customer data by QR code from DynamoDB', { qrCode, languageCode });
    let response: { customer?: Customer, authorizationToken?: string };

    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginOrder(qrCode, languageCode);
    } else {
      response = await ApiUtil.postRequest<{ customer: Customer, authorizationToken?: string }>(CallerType.ORDER, "login/loginOrder", { qrCode, languageCode });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(CallerType.ORDER, response.authorizationToken);
    }

    return response.customer;
  }

  public static async loginAdminRoot(userId: string, password: string): Promise<User | undefined> {
    Log.debug('Attempting to login user', { userId });
    let response: { user?: User, authorizationToken?: string };

    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginAdminRoot(userId, password);
    } else {
      response = await ApiUtil.postRequest<{ user: User, authorizationToken?: string }>(CallerType.ADMIN, "login/loginAdminRoot", { userId, password });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(CallerType.ADMIN, response.authorizationToken);
    }

    return response.user;
  }

  public static async loginAdmin(userId: string, password: string): Promise<User | undefined> {
    Log.debug('Attempting to login user', { userId });
    let response: { user?: User, authorizationToken?: string };

    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginAdmin(userId, password);
    } else {
      response = await ApiUtil.postRequest<{ user: User, authorizationToken?: string }>(CallerType.ADMIN, "login/loginAdmin", { userId, password });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(CallerType.ADMIN, response.authorizationToken);
    }

    return response.user;
  }

  public static async loginRoot(userId: string, password: string): Promise<boolean> {
    Log.debug('Attempting root login', { userId });

    let response: { success: boolean, authorizationToken?: string } = { success: false };

    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginRoot(userId, password);
    } else {
      response = await ApiUtil.postRequest<{ success: boolean, authorizationToken?: string }>(CallerType.ROOT, "login/loginRoot", { userId, password });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(CallerType.ROOT, response.authorizationToken);
    }

    return response.success;
  }

  public static async loginRegistration(): Promise<{ userId: string; authorizationToken: string }> {
    Log.debug('Registration login of a new user');

    let response: { userId: string; authorizationToken: string };

    if (ApiUtil.isLocalApi()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginRegistration();
    } else {
      response = await ApiUtil.postRequest<{ userId: string; authorizationToken: string }>(
        CallerType.ADMIN, 
        "loginRegistration", 
        {}
      );
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(CallerType.ADMIN, response.authorizationToken);
    }

    return { userId: response.userId, authorizationToken: response.authorizationToken };
  }

  private static async getLoginApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).LoginApi;
  }

}

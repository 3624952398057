import { useState, useEffect, useRef } from "react";
import {
    Paper,
    Container,
    Stack,
    Typography,
    Box,
    Grid,
    Grid2,
    IconButton,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import { useAppContext } from "../components/AppProvider";
import { DraggableList } from "../components/DraggableList";
import { CustomAppBar } from "../components/CustomAppBar";
import {
    ProductCategory,
    ProductsTextsUtil,
    IDUtil,
    CallerType,
    ProductFilter,
    Customer,
    Log
} from "base.f6st.com";
import {
    S3Client,
    CustomerClient,
    StandardButton,
    ProductCard
} from "common.f6st.com";

import { CardUseCase } from "common.f6st.com/src/gui/ProductCard";
import { Link, useLocation } from "react-router-dom";

export const ProductsPage = () => {
    const { getCustomer, loadCustomer } = useAppContext();
    const [categories, setCategories] = useState<ProductCategory[]>([]);
    const [filters, setFilters] = useState<ProductFilter[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<ProductCategory | undefined>(undefined);
    const [selectedFilter, setSelectedFilter] = useState<ProductFilter | undefined>(undefined);
    const [txt, setTxt] = useState<ProductsTextsUtil | undefined>(undefined);
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);  // Ensure customer is state
    const location = useLocation();
    const fetchInitiated = useRef(false);

    useEffect(() => {
        const fetchProducts = async () => {
            let customerCurrent: Customer = location.state?.customer;

            // Fetch customer data if not coming as a param from the customer detail page
            if (!customerCurrent) {
                const customerToLoad = getCustomer();
                customerCurrent = await loadCustomer(
                    customerToLoad.id,
                    customerToLoad.businessSettings.languageCodePrimary
                );
            }

            setCustomer(customerCurrent); // Set customer in state

            // Fetch product details using customer
            const txt = new ProductsTextsUtil(customerCurrent);
            const products = customerCurrent.products;

            const allCategory: ProductCategory = {
                id: "all",
                products: products.categories.flatMap((cat) => cat.products),
            };
            const allFilter: ProductFilter = { id: "all" };
            const noFilter: ProductFilter = { id: "noFilter" };

            const copiedCategories = [
                allCategory,
                ...products.categories.map((category) => ({ ...category })),
            ];
            const copiedFilters = [
                allFilter,
                noFilter,  // Adding the No Filter option
                ...products.filters.map((filter) => ({ ...filter })),
            ];

            setCategories(copiedCategories);
            setFilters(copiedFilters);
            setTxt(txt);

            setSelectedCategory(location.state?.selectedCategory ? location.state?.selectedCategory : allCategory);
            setSelectedFilter(allFilter);
        };

        if (!fetchInitiated.current) {
            fetchProducts();
            fetchInitiated.current = true;
        }
    }, [customer, loadCustomer]);

    const countProductsInCategory = (category: ProductCategory, filterId?: string) => {
        if (!category) return 0;
        if (!filterId || filterId === "all") {
            return category.products.length;
        }

        if (filterId === "noFilter") {
            return category.products.filter(product => !product.filterIds || product.filterIds.length === 0).length;
        }

        return category.products.filter(product => product.filterIds?.includes(filterId)).length;
    };

    const handleCategorySelected = (category: ProductCategory) => {
        if (category.id === "all") {
            setSelectedCategory(categories[0]);
        } else {
            setSelectedCategory(category);
        }
    };

    const handleAddCategory = () => {
        const newCategory: ProductCategory = {
            id: IDUtil.getShortId(),
            products: [],
        };
        setCategories([...categories, newCategory]);
    };

    const handleDeleteCategory = (category: ProductCategory) => {
        setCategories(categories.filter(cat => cat.id !== category.id));
        setSelectedCategory(undefined);
    };

    const handleFilterSelected = (filter: ProductFilter) => {
        if (filter.id === "all") {
            setSelectedFilter(filters[0]);
        } else {
            setSelectedFilter(filter);
        }
    };

    const handleAddFilter = () => {
        const newFilter: ProductFilter = { id: IDUtil.getShortId() };
        setFilters([...filters, newFilter]);
    };

    const handleDeleteFilter = (filter: ProductFilter) => {
        setFilters(filters.filter(f => f.id !== filter.id));

        setCategories(prevCategories => {
            return prevCategories.map(cat => ({
                ...cat,
                products: cat.products.map(prod => ({
                    ...prod,
                    filterIds: prod.filterIds?.filter(id => id !== filter.id),
                })),
            }));
        });
    };

    const handleSave = async () => {
        if (!txt || !customer) return;
        const products = customer.products;

        products.categories = categories.slice(1);
        products.filters = filters.slice(1);

        await S3Client.cleanup(CallerType.ADMIN, customer.id, []);
        await CustomerClient.updateProductsPrimary(
            CallerType.ADMIN,
            customer.id,
            products,
            txt.allProductsTexts,
            customer.businessSettings.languageCodePrimary
        );
        toast.success("Products saved successfully");
    };

    const filteredProducts = (selectedCategory?.products || []).filter(product => {
        if (!selectedFilter || selectedFilter.id === "all") return true;
        if (selectedFilter.id === "noFilter") {
            return !product.filterIds || product.filterIds.length === 0;
        }
        return product.filterIds?.includes(selectedFilter.id) || false;
    });

    if (!txt || !customer) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <>
            <CustomAppBar backButton backButtonUrl="/" pageTitle="Products" />
            <Container>

                <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <DraggableList
                                items={categories.map(category => {
                                    const defaultName =
                                        category.id === "all"
                                            ? "All"
                                            : txt.get(category.id) || "Unnamed Category";
                                    const productCount = countProductsInCategory(
                                        category,
                                        selectedFilter?.id
                                    );
                                    const nameWithCount = `${defaultName} (${productCount})`;
                                    return {
                                        ...category,
                                        name: nameWithCount,
                                    };
                                })}
                                title="Categories"
                                onItemSelect={handleCategorySelected}
                                onItemDelete={handleDeleteCategory}
                                onAddItem={handleAddCategory}
                                onDragEnd={(updatedItems: ProductCategory[]) =>
                                    setCategories(updatedItems)
                                }
                                defaultSelectedId={(selectedCategory?.id ? selectedCategory?.id : 'all')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DraggableList
                                items={filters.map(filter => {
                                    const defaultName = filter.id === "all"
                                        ? "All"
                                        : filter.id === "noFilter"
                                        ? "No Filter"
                                        : txt.get(filter.id) || "Unnamed Filter";
                                    const totalProductsWithFilter = countProductsInCategory(
                                        selectedCategory || { id: 'all', products: [] },
                                        filter.id
                                    );
                                    const nameWithCount = `${defaultName} (${totalProductsWithFilter})`;
                                    return {
                                        ...filter,
                                        name: nameWithCount,
                                    };
                                })}
                                title="Filters"
                                onItemSelect={handleFilterSelected}
                                onItemDelete={handleDeleteFilter}
                                onAddItem={handleAddFilter}
                                onDragEnd={function (updatedItems: { name: string; id: string; }[]): void {
                                    throw new Error("Function not implemented.");
                                }}
                                defaultSelectedId="all"
                            />
                        </Grid>
                    </Grid>

                    <Container>
                        <Box display='flex' flexDirection='column' gap={2} pb={12} mt={4}>
                            {selectedCategory && (
                                <Grid container spacing={3}>

                                    <Box mt={2} display='flex' flexDirection='column' gap={2} key={selectedCategory.id} id={selectedCategory.id}>
                                        <Grid2 container spacing={2}>
                                            {filteredProducts.map((product) => {
                                                return (
                                                    <Link
                                                        key={product.id}
                                                        style={{ textDecoration: "none" }}
                                                        to={`/product/${product.id}`}
                                                        state={{ product, customer, selectedCategory }}
                                                    >
                                                        <ProductCard
                                                            key={product.id}
                                                            product={product}
                                                            categoryId={selectedCategory.id}
                                                            customer={customer}
                                                            useCase={CardUseCase.ADMIN}
                                                            txt={txt}
                                                        />
                                                    </Link>
                                                );
                                            })}

                                            {/* Add New Product Button */}
                                            {selectedCategory.id !== "all" && (
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Link
                                                        to={`/product/add}`}
                                                        state={{
                                                            product: {
                                                                id: IDUtil.getShortId(),
                                                                sizes: [],
                                                                extras: [],
                                                                filterIds: [],
                                                                descriptionId: IDUtil.getShortId(),
                                                            },
                                                            customer,
                                                            categoryId: selectedCategory.id,
                                                            selectedCategory
                                                        }}
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <Paper
                                                            elevation={1}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                                p: 2,
                                                                minWidth: '310px',
                                                                minHeight: '108px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <IconButton
                                                                style={{ width: '100px', height: '100px' }}
                                                            >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </Paper>
                                                    </Link>
                                                </Grid>
                                            )}

                                        </Grid2>
                                    </Box>

                                </Grid>
                            )}
                        </Box>
                    </Container>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        sx={{ mt: -6 }}
                    >
                        {categories.length > 0 && (
                            <StandardButton text="Save All" action={handleSave} />
                        )}
                    </Stack>
                </Paper>
            </Container>
        </>
    );
};

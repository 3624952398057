import { useEffect, useState } from 'react';
import { Box, Container, Paper, TextField, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoginClient, MailClient, SecurityCodeClient, StandardButton, UserClient, UserUtil } from 'common.f6st.com';
import { useAppContext } from '../components/AppProvider';
import { toast } from 'react-toastify';
import { ForgotUserCodeDialog } from '../components/dialogs/ForgotUserCodeDialog';
import { ResetPasswordDialog } from '../components/dialogs/ResetPasswordDialog';
import { CustomAppBar } from '../components/CustomAppBar';
import { CallerType, Log, User } from 'base.f6st.com';

export const LoginPage = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [codeId, setCodeId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [user, setUser] = useState<User | null>(null);
  const [forgotUserCodeOpen, setForgotUserCodeOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const { getLoggedInUser } = useAppContext();

  useEffect(() => {
    if (getLoggedInUser()) {
      navigate('/');
    }
  }, [appContext, navigate]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setError('');

    if (isFirstLogin) {
      if (step === 2) {
        const emailExists = await MailClient.isEmailExists(CallerType.ADMIN, email.trim());
        if (emailExists) {
          setError('Email already exists.');
          return;
        }

        const subject = "F6ST verification code";
        const body = "Your login verification code is: {code}";
        const codeId = await SecurityCodeClient.createSecurityCodeMail(CallerType.ADMIN, email.trim(), subject, body, userId);
        if (codeId) {
          setCodeId(codeId);
          toast.success("Verification code sent to your email");
        } else {
          toast.error("Error at sending your first verification code");
        }

        setStep(3);
      } else if (step === 3) {
        handleFirstLogin();
      }
    } else {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setError(''); // Clear error state

    if (step === 1) {
      const trimmedUserId = userId.trim();

      // check if hte given user login id exists 
      const isFirstLogin = await LoginClient.isFirstAdminLogin(trimmedUserId);
      if (isFirstLogin === undefined) {
        setError('User Code does not exist');
        return;
      }

      if (isFirstLogin === true) {
        setIsFirstLogin(true);
      }
      
      setStep(2);
    } else if (step === 2) {
      try {
        await appContext.login(userId.trim(), password.trim());
        navigate('/');
      } catch (error) {
        setError('Invalid credentials');
        console.error('Login error:', error);
      }
    }
  };

  const handleFirstLogin = async () => {
    const trimmedPassword = newPassword.trim();
    const trimmedConfirmPassword = confirmNewPassword.trim();

    const passwordCheckResult = UserUtil.checkPassword(trimmedPassword, trimmedConfirmPassword);
    if (passwordCheckResult === UserUtil.PASSWORD_COMPLEXITY_ERROR) {
      setError('Password does not meet complexity requirements.');
      return;
    }
    if (passwordCheckResult === UserUtil.PASSWORD_MISMATCH_ERROR) {
      setError('Passwords do not match.');
      return;
    }

    const matchCode = await UserClient.updateCustomerFirstLogin(CallerType.ADMIN, codeId, verificationCode, email, name, password);
    if (!matchCode) {
      setError('Invalid verification code.');
      return;
    }

    await appContext.login(userId.trim(), newPassword.trim());
    navigate('/');
  };

  return (
    <>
      <CustomAppBar pageTitle="Login" showProfile={false} />
      <Container>
        <Box component="form" onSubmit={handleSubmit} mt={4} sx={{ display: 'flex', marginX: 'auto', maxWidth: '400px', justifyContent: 'center', alignItems: 'center' }}>
          <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '12px', width: '100%' }}>
            {step === 1 && (
              <>
                <TextField
                  label="User Code"
                  variant="outlined"
                  type="text"
                  value={userId}
                  onChange={(e) => {
                    setUserId(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                {error && <Typography color="error">{error}</Typography>}
                <StandardButton
                  text="Next"
                  action={handleSubmit}
                />
                <Link onClick={() => setForgotUserCodeOpen(true)} variant="body2" align="center" style={{ cursor: 'pointer' }}>
                  Forgot User Code?
                </Link>
              </>
            )}
            {step === 2 && !isFirstLogin && (
              <>
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                {error && <Typography color="error">{error}</Typography>}
                <StandardButton
                  text="Login"
                  action={handleSubmit}
                />
                <Link onClick={() => setResetPasswordOpen(true)} variant="body2" align="center" style={{ cursor: 'pointer' }}>
                  Reset Password
                </Link>
              </>
            )}
            {step === 2 && isFirstLogin && (
              <>
                <Typography>Welcome to your first login!</Typography>
                <TextField
                  label="Name"
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                {error && <Typography color="error">{error}</Typography>}
                <StandardButton
                  text="Next"
                  action={handleSubmit}
                />
              </>
            )}
            {step === 3 && (
              <>
                <TextField
                  label="Verification Code"
                  variant="outlined"
                  type="text"
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    setError(''); // Clear error state
                  }}
                  required
                />
                {error && <Typography color="error">{error}</Typography>}
                {message && <Typography color="primary">{message}</Typography>}
                <StandardButton
                  text="Next"
                  action={handleSubmit}
                />
              </>
            )}
          </Paper>
        </Box>
      </Container>

      <ForgotUserCodeDialog open={forgotUserCodeOpen} onClose={() => setForgotUserCodeOpen(false)} />
      <ResetPasswordDialog open={resetPasswordOpen} onClose={() => setResetPasswordOpen(false)} userId={userId} />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../components/AppProvider';
import { Log } from 'base.f6st.com';

export const LoginRootPage: React.FC = () => {
  const [error, setError] = useState('');
  const { loginRoot } = useAppContext(); 
  const navigate = useNavigate();
  const location = useLocation();

  const getQueryParam = (param: string) => {
    const params = new URLSearchParams(location.search);
    return params.get(param) || '';
  };

  useEffect(() => {
    const userName = getQueryParam('u');
    const password = getQueryParam('p');
    const customerId = getQueryParam('c'); 

    Log.debug("Logging in as a root", { userName, password, customerId });

    const handleLogin = async () => {
        await loginRoot(userName.trim(), password.trim(), customerId.trim());
        navigate('/');  // Navigate to the root route after successful login
    };

    if (userName && password && customerId) {
      handleLogin();
    } else {
      setError('Login parameters are missing');
    }
  }, [location, loginRoot, navigate]);

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
        </Box>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

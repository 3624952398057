// EnvUtil.ts

import { AppError } from "./AppError";

declare var window: any;

export class EnvUtil {

    public static isBrowser(): boolean {
        return typeof window !== 'undefined' && typeof window.console !== 'undefined';
    }

    public static getWindow(): any {
        if (!this.isBrowser()) {
            throw new AppError('No window object available');
        }
        return window;
    }


}

import { CallerType, EnvUtil, Log, User } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class SecurityCodeClient {

  public static async createSecurityCodeMail(
    caller: CallerType,
    email: string,
    subject: string,
    body: string,
    userId?: string
  ): Promise<string | undefined> {
    Log.debug('Creating security code and sending via email', { email, subject });
    if (ApiUtil.isLocalApi()) {
      const SecurityCodeApi = await this.getSecurityCodeApi();
      return await SecurityCodeApi.createSecurityCodeMail(email, subject, body, userId);
    } else {
      return await ApiUtil.postRequest<string | undefined>(caller, "securityCode/createSecurityCodeMail", { email, subject, body, userId });
    }
  }

  public static async validateSecurityCode(
    caller: CallerType,
    id: string,
    value: string
  ): Promise<{ user?: User; isValid: boolean }> {
    Log.debug('Validating security code', { id, value });
  
    if (ApiUtil.isLocalApi()) {
      const SecurityCodeApi = await this.getSecurityCodeApi();
      return await SecurityCodeApi.validateSecurityCode(id, value);
    } else {
      return await ApiUtil.postRequest<{ user?: User; isValid: boolean }>(
        caller,
        "securityCode/validateSecurityCode",
        { id, value }
      );
    }
  }


  private static async getSecurityCodeApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).SecurityCodeApi;
  }
}

import { Order, ProductsTextsUtil } from 'base.f6st.com';
import * as XLSX from 'xlsx';

export class XLSUtil {
  public static downloadOrders(orders: Order[], texts: ProductsTextsUtil) {
    const orderData = orders.map(order => ({
      id: order.id,
      customerId: order.customerId,
      buyerId: order.buyerId,
      date: order.date.toISOString(),
      orderStatus: order.orderStatus,
      paymentStatus: order.paymentStatus,
      currency: order.currency,
      amount: order.totalAmount,
      refundDate: order.refundDate ? order.refundDate.toISOString() : undefined,
      qrCode: order.qrCode,
      comebackDiscountPercentage: order.discountPercentage,
      tipPercentage: order.tipPercentage,
      priority: order.priority,
      testMode: order.testMode
    }));

    const orderItemsData = orders.flatMap(order =>
      order.items.map(item => ({
        orderId: order.id,
        itemId: item.id,
        productId: item.product.id,
        productName: texts.get(item.product.id),
        quantity: item.quantity,
        sizeId: item.sizeId,
        extraIds: item.extraIds ? item.extraIds.join(', ') : undefined
      }))
    );

    const orderWorksheet = XLSX.utils.json_to_sheet(orderData);
    const orderItemsWorksheet = XLSX.utils.json_to_sheet(orderItemsData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, orderWorksheet, 'Orders');
    XLSX.utils.book_append_sheet(workbook, orderItemsWorksheet, 'OrderItems');

    const date = new Date().toISOString().split('T')[0];
    XLSX.writeFile(workbook, `orders_${date}.xlsx`);
  }
}

import { CallerType, Log, Order, OrderStatus } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class OrdersClient {

    public static async putOrder(caller: CallerType, order: Order): Promise<void> {
        Log.debug('Saving order:', order);
        if (ApiUtil.isLocalApi()) {
            const OrderApi = await this.getOrdersApi();
            await OrderApi.putOrder(order);
        } else {
            await ApiUtil.postRequest<void>(caller, 'orders/putOrder', order);
        }
    }

    public static async queryOrders(caller: CallerType, customerId: string, orderStatus: OrderStatus, fromDate: Date, toDate?: Date): Promise<Order[]> {
        Log.debug('Loading orders for customer:', customerId);
        if (ApiUtil.isLocalApi()) {
            const OrderApi = await this.getOrdersApi();
            return await OrderApi.queryOrders(customerId, orderStatus, fromDate, toDate);
        } else {
            return await ApiUtil.postRequest<Order[]>(caller, 'orders/queryOrders', { customerId, orderStatus, fromDate, toDate });
        }
    }

    public static async updateOrder(caller: CallerType, orderId: string, customerId: string, fields: { priority?: boolean, orderStatus?: OrderStatus, qrCodeLocation?: string }): Promise<void> {
        Log.debug('Updating order:', { orderId, customerId, fields });
        if (ApiUtil.isLocalApi()) {
            const OrderApi = await this.getOrdersApi();
            await OrderApi.updateOrder(orderId, customerId, fields);
        } else {
            await ApiUtil.postRequest<void>(caller, 'orders/updateOrder', { orderId, customerId, fields });
        }
    }

    private static async getOrdersApi() {
        // @ts-ignore: Dynamic import may not resolve during production build
        return (await import('api.f6st.com')).OrdersApi;
    }
}
